const feathers = require("@feathersjs/feathers");
const rest = require("@feathersjs/rest-client");
const auth = require("@feathersjs/authentication-client");
const axios = require("axios");

const BASE_URL = process.env.VUE_APP_BEEZ_API_URL || "http://localhost:3030";
// const BASE_URL = "http://localhost:3030";

const app = feathers();

const restClient = rest(BASE_URL);
app.configure(restClient.axios(axios));
app.configure(
  auth({
    storage: window.localStorage,
    storageKey: "beez-jwt-token",
  })
);
// Temporary Local Storage Implementations
export const Current_Account_ID = "accountId";

//services
const usersService = app.service("users");
const beezInCodeValidationService = app.service("validate-beez-in-code");
const authManagementService = app.service("authmanagement");
const fetchUsersService = app.service("account-users");
const fetchModuleUsersService = app.service("module-users");

const accountService = app.service("accounts");
const invitationService = app.service("invitations");
const fetchAccountService = app.service("user-accounts");
const beezGalleryService = app.service("beez-gallery");
const orientationCardService = app.service("orientation-card");
const introductionCardService = app.service("card-customization-module");
const accountModules = app.service("account-modules");
const welcomeCardService = app.service("welcome-card");
const communicationService = app.service("communication-module");
const communicationPermissionService = app.service("communication-permissions");

const filesService = app.service("files");
const userCardService = app.service("card");
const userModulesService = app.service("modules");
const beezModules = app.service("modules");
const trainingService = app.service("trainings");
const courseService = app.service("courses");
const materialService = app.service("course-materials");
const submissionService = app.service("submission-steps");
const userSubmissionsService = app.service("submissions");
const courseSubmissionsService = app.service("course-submissions");
const criteriaService = app.service("criteria");
const completeSubmissionsService = app.service("complete-submission");
const submissionEvaluationService = app.service("submission-evaluations");
const courseSubmissionEvaluationsService = app.service(
  "course-submission-evaluations"
);

// Login
export async function loginUser(email, password) {
  const user = await app.authenticate({
    strategy: "local",
    email: email,
    password: password,
  });

  const beezAccountId = user.accounts[0] || "";
  localStorage.setItem(
    Current_Account_ID,
    beezAccountId
  ); /* Stores Current Account Id */
  return user;
}

export async function getUser(id) {
  return await usersService.get(id);
}

export async function signUpUser(payload) {
  return await usersService.create(payload);
}

export async function longTokenValidation(token) {
  return await beezInCodeValidationService.find({
    query: {
      token: token,
    },
  });
}
export async function getComments(evaluatorId, submissionId) {
  return await submissionEvaluationService.find({
    query: {
      evaluatorId,
      submissionId,
    },
  });
}

export async function addComments(submissionId, comment) {
  return await submissionEvaluationService.create({
    submissionId: submissionId,
    comment: comment,
  });
}
export async function beezInCodeValidation(code) {
  return await beezInCodeValidationService.create({
    code: code,
  });
}

// Re-auth
export async function reauth() {
  return await app.authentication.reAuthenticate();
}

// Logout
export async function logoutUser() {
  return await app.logout();
}

export async function beezIn(userId, accountId) {
  return await usersService.patch(userId, {
    beezInToAccount: accountId,
  });
}

export async function emailVerification(code, email) {
  return await authManagementService.create({
    action: "verifySignupShort",
    value: {
      user: {
        email: email,
      },
      token: code,
    },
  });
}

export async function sendVerificationCode(email) {
  return await authManagementService.create({
    action: "resendVerifySignup",
    value: {
      email,
    },
  });
}

export async function askPasswordReset(email) {
  return await authManagementService.create({
    action: "sendResetPwd",
    value: {
      email,
    },
  });
}

export async function resetPassword(token, password) {
  return await authManagementService.create({
    action: "resetPwdLong",
    value: {
      token,
      password,
    },
  });
}

// Get All Account Owners
export async function getAllAccountUsers(level, accountid) {
  return await fetchUsersService.find({
    headers: {
      accountid: accountid,
    },
  });
}

export async function getOnBoardingModules(configurationsIds, accountId) {
  console.log(configurationsIds, "config IDS are here");
  const { data } = await app
    .service(
      `preferences?accountId=${accountId}&configurationId[$in]=${configurationsIds[0]}&configurationId[$in]=${configurationsIds[1]}&configurationId[$in]=${configurationsIds[2]}`
    )
    .find();
  // const { data } = await onBoardingModuleService.find({
  //   params: {
  //     accountId: accountId,
  //     configurationId[$in]: configurationIds[0]
  //   },
  // });

  return data;
}

export async function getElevationModules(configurationsIds, accountId) {
  const { data } = await app
    .service(
      `preferences?accountId=${accountId}&configurationId[$in]=${configurationsIds[0]}&configurationId[$in]=${configurationsIds[1]}`
    )
    .find();
  // const { data } = await onBoardingModuleService.find({
  //   params: {
  //     accountId: accountId,
  //     configurationId[$in]: configurationIds[0]
  //   },
  // });

  console.log(data, "The config data");
  return data;
}

//Get Account Modules
export async function getAccountModules(accountid) {
  const { data } = await accountModules.find({
    headers: {
      accountid: accountid,
    },
  });

  return data;
}

export async function editAccount(accountId, accountName, companyName, domain) {
  const account = await accountService.patch(
    accountId,
    {
      name: accountName,
      domain: domain,
      companyName: companyName,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );

  return account;
}

export async function inviteAccountOwner(email, accountId, assignedRole) {
  const token = localStorage.getItem("beez-jwt-token");
  const invitee = await invitationService.create(
    {
      email: email,
      accountId: accountId,
      assignedRole: assignedRole,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return invitee;
}

export async function currentUser() {
  const user = await app.get("authentication");
  return user;
}

export async function getAllAccounts() {
  return await fetchAccountService.find();
}

export async function getGalleryContent() {
  return await beezGalleryService.find();
}
//Welcome Card

export async function createWelcomeCard(
  photoId,
  title,
  description,
  links,
  accountId,
  configurationId,
  accountModuleId
) {
  return await welcomeCardService.create({
    photoId,
    title,
    description,
    links,
    configurationId,
    accountId,
    accountModuleId,
  });
}
export async function patchWelcomeCard(
  cardId,
  photoId,
  title,
  description,
  links,
  accountId
) {
  return await welcomeCardService.patch(
    cardId,
    {
      photoId,
      title,
      description,
      links,
      accountId,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
}

export async function getModules(accountId) {
  return await beezModules.find({
    headers: {
      accountId,
    },
  });
}

export async function getWelcomeCard(accountId) {
  const data = await welcomeCardService.find({
    headers: {
      accountId: accountId,
    },
  });
  return data;
}

export async function createOrientationCard(
  accountId,
  tasks,
  configurationId,
  accountModuleId
) {
  return await orientationCardService.create({
    tasks,
    configurationId,
    accountId,
    accountModuleId,
  });
}

export async function patchOrientationCard(cardId, accountId, tasks) {
  return await orientationCardService.patch(
    cardId,
    {
      tasks,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
}

export async function getOrientationTasks(accountId) {
  return await orientationCardService.find({
    headers: {
      accountId: accountId,
    },
  });
}

export async function createIntroductionCard(
  jobTitle,
  aboutTitle,
  sections,
  accountId,
  configurationId,
  accountModuleId
) {
  return await introductionCardService.create(
    {
      jobTitle,
      aboutTitle,
      sections,
      configurationId,
      accountId,
      accountModuleId,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
}

export async function getIntroductionCard(accountId) {
  return await introductionCardService.find({
    headers: {
      accountId: accountId,
    },
  });
}

export async function patchIntroductionCard(accountId, sectionId, title) {
  return await app.service("card-customization-section").patch(
    sectionId,
    {
      title: title,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
}

export async function patchIntroductionCardTitles(
  jobTitle,
  aboutTitle,
  accountId,
  cardId
) {
  return await introductionCardService.patch(
    cardId,
    {
      jobTitle: jobTitle,
      aboutTitle: aboutTitle,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
}

export async function getUserAccounts() {
  return await userAccountService.find();
}
export async function filesUpload(file) {
  const token = await app.authentication.getAccessToken();
  return await axios
    .post(`${BASE_URL}/files`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
}

export async function organizationLogoEdit(orgId, orgLogoId) {
  const orgNameAndLogo = await organizationService.patch(orgId, {
    logoFileId: orgLogoId,
  });
  return orgNameAndLogo;
}

export async function createCommunicationModule(accountId) {
  const data = await communicationService.create({
    accountId: accountId,
  });

  return data;
}
export async function setCommunicationPermission(
  adminIds,
  accountId,
  permission,
  accountModuleId
) {
  const token = localStorage.getItem("beez-jwt-token");
  const permissions = await communicationPermissionService.create(
    {
      adminIds: adminIds,
      accountId: accountId,
      permission: permission,
      accountModuleId: accountModuleId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
  return permissions;
}

export async function getAccountModuleMembers(
  level,
  accountid,
  accountmoduleid
) {
  const modulesMembers = await fetchModuleUsersService.find({
    headers: {
      accountid: accountid,
      accountmoduleid: accountmoduleid,
    },
  });
  return modulesMembers;
}

export async function updateAccount(accountId, payload) {
  return await accountService.patch(accountId, payload, {
    headers: {
      accountId,
    },
  });
}

export async function getAccount(accountId, id) {
  return await accountService.get(id, {
    headers: {
      accountId,
    },
  });
}

export async function accountLogoEdit(accountId, logoId) {
  const account = await accountService.patch(
    accountId,
    {
      logoFileId: logoId,
    },
    {
      headers: {
        accountId: accountId,
      },
    }
  );
  return account;
}

export async function fetchFilesUploaded(logoId) {
  const orgLogoImage = await filesService.get(logoId);
  return orgLogoImage;
}

export async function getFile(id) {
  return await filesService.get(id);
}

export async function fetchCommunicationModule(accountId) {
  const token = localStorage.getItem("beez-jwt-token");
  const data = await communicationService.find({
    headers: {
      accountid: accountId,
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  return data;
}

export async function createUserCard(cardData) {
  const data = await userCardService.create({
    profilePictureId: cardData.profilePictureId,
    fullName: cardData.fullName,
    about: cardData.about,
    position: cardData.position,
    cardCustomizationId: cardData.cardCustomizationId,
    cardTags: [
      {
        cardCustomizationSectionId:
          cardData.cardTags[0].cardCustomizationSectionId,
        tags: cardData.cardTags[0].tags,
      },
      {
        cardCustomizationSectionId:
          cardData.cardTags[1].cardCustomizationSectionId,
        tags: cardData.cardTags[1].tags,
      },
    ],
    accountModuleId: cardData.accountModuleId,
  });
  return data;
}

export async function getUserCard() {
  const data = await userCardService.find();
  return data;
}

export async function getUserAccountModules() {
  const data = await userModulesService.find();
  return data;
}

export async function fetchTraining(accountId) {
  const data = await trainingService.find({
    headers: {
      accountId: accountId,
    },
  });
  return data;
}

export async function fetchEvaluatorTraining(courseIds, accountId) {
  let request = "trainings?";

  for (let i in courseIds) {
    request = request + `courseIds=${courseIds[i]}&`;
  }

  const { data } = await app.service(request).find({
    headers: {
      accountId,
    },
  });

  return data;
}

export async function createTraining(
  name,
  description,
  configurationId,
  accountId,
  accountModuleId
) {
  const data = await trainingService.create({
    name: name,
    description: description,
    configurationId: configurationId,
    accountId: accountId,
    accountModuleId,
  });
  return data;
}

/**
 * Find training by training ids
 * @param {} accountId - account id
 * @param {*} trainingIds - array of training ids
 */
export async function findTrainings(accountId, trainingIds) {
  return await trainingService.find({
    query: {
      _id: { $in: trainingIds },
    },
    headers: {
      accountId,
    },
  });
}

export async function getTraining(accountId, id) {
  return await trainingService.get(id, {
    headers: {
      accountId,
    },
  });
}

export async function getEvaluatorTraining(accountId, courseIds) {
  return await trainingService.find({
    query: {
      courseIds: { $in: courseIds },
    },
    headers: {
      accountId,
    },
  });
}

export async function getSubmissionData(accountId, courseId, limit, skip) {
  const data = await courseSubmissionsService.find({
    headers: {
      accountId,
      courseId,
    },
    query: {
      $limit: limit,
      $skip: skip,
    },
  });

  return data;
}
export async function createCourse(
  name,
  description,
  trainingId,
  level,
  accountId
) {
  const data = await courseService.create({
    name,
    description,
    trainingId,
    accountId,
    level,
  });
  return data;
}

export async function patchCourse(patchData) {
  const data = await courseService.patch(patchData.courseId, {
    name: patchData.name,
    description: patchData.description,
    startDate: patchData.startDate,
    endDate: patchData.endDate,
    prerequisiteCourseIds: patchData.prerequisiteCourseIds,
    level: patchData.level,
    instructorName: patchData.instructorName,
    instructorPhotoId: patchData.instructorPictureId,
    agenda: patchData.agendas,
    isComplete: patchData.isComplete,
  });

  return data;
}

export async function getCourse(accountId, id) {
  return await courseService.get(id, {
    headers: {
      accountId,
    },
  });
}
export async function getCourses(accountId, trainingId) {
  const data = await courseService.find({
    query: {
      trainingId,
    },
    headers: {
      accountId,
    },
  });
  return data;
}

export async function getSpecificCourse(accountid, trainingId, courseId) {
  const data = await courseService.get(courseId, {
    headers: {
      accountid: accountid,
      trainingId: trainingId,
    },
  });
  return data;
}

export async function addMaterial(courseId, title, subtitle, fileId) {
  const data = await materialService.create({
    courseId: courseId,
    name: title,
    description: subtitle,
    fileId: fileId,
  });
  return data;
}

export async function fetchMaterials(materialsId) {
  const data = await materialService.find({
    query: {
      _id: {
        $in: materialsId,
      },
    },
  });
  return data;
}

export async function deleteMaterial(patchData) {
  const data = await courseService.patch(patchData.courseId, {
    materialIds: patchData.materialIds,
  });
  return data;
}

export async function addSubmissionSteps(
  courseId,
  title,
  description,
  fileType,
  isOptional
) {
  const data = await submissionService.create({
    courseId: courseId,
    name: title,
    description: description,
    fileType: fileType,
    isOptional: isOptional,
  });
  return data;
}

export async function getSubmissionSteps(courseId) {
  const data = await submissionService.find({
    query: {
      courseId: courseId,
    },
  });
  return data;
}

export async function getSubmissionsData(courseId, accountId) {
  const data = await su;
}
export async function deleteSubmissionSteps(patchData) {
  const data = await courseService.patch(patchData.courseId, {
    submissionStepIds: patchData.submissionStepsIds,
  });
  return data;
}

export async function addCriteria(courseId, description, ratingType) {
  const data = await criteriaService.create({
    courseId: courseId,
    description: description,
    ratingType: ratingType,
  });
  return data;
}

export async function getCriteria(criteriaIds, courseId) {
  const data = await criteriaService.find({
    query: {
      _id: {
        $in: criteriaIds,
      },
    },
    headers: {
      courseId,
    },
  });
  return data;
}

export async function deleteCriteria(patchData) {
  const data = await courseService.patch(patchData.courseId, {
    criteriaIds: patchData.criteriaIds,
  });
  return data;
}
export async function patchEvaluationDeadline(courseId, deadline) {
  const data = await courseService.patch(courseId, {
    evaluationDeadline: deadline,
  });

  return data;
}
export async function inviteCourseEvaluator(
  email,
  accountId,
  courseId,
  assignedRole
) {
  const invitee = await invitationService.create({
    email: email,
    accountId: accountId,
    courseId: courseId,
    assignedRole: assignedRole,
  });
  return invitee;
}

export async function deleteEvaluator(patchData) {
  const data = await courseService.patch(patchData.courseId, {
    evaluatorsIds: patchData.evaluatorsIds,
  });
  return data;
}

export async function getSubmissionForSteps(accountId, userId, stepsIds) {
  return await userSubmissionsService.find({
    query: {
      submissionStepId: { $in: stepsIds },
      userId,
    },
    headers: {
      accountId,
    },
  });
}

export async function getSubmissions(userId, submissionStepId) {
  const submissions = await userSubmissionsService.find({
    headers: {
      submissionStepId,
    },
    query: {
      userId,
    },
  });
  return submissions;
}

export async function createStepSubmission(accountId, payload) {
  return await userSubmissionsService.create(payload, {
    headers: {
      accountId,
    },
  });
}

export async function updateStepSubmission(accountId, payload) {
  return await userSubmissionsService.patch(payload._id, payload, {
    headers: {
      accountId,
    },
  });
}

export async function updateStepFilesSubmission(accountId, id, fileIds) {
  return await userSubmissionsService.patch(
    id,
    {
      $push: { fileIds },
    },
    {
      headers: {
        accountId,
      },
    }
  );
}

export async function createSubmission(
  submissionStepId,
  text,
  fileIds,
  userId
) {
  const submit = await userSubmissionsService.create({
    submissionStepId: submissionStepId,
    text: text,
    fileIds: fileIds,
    userId: userId,
  });

  return submit;
}

export async function patchsubmittedFiles(submissionStepId, text, fileIds) {
  console.log("iAPI=>", text, fileIds);
  const submit = await userSubmissionsService.patch(submissionStepId, {
    text: text || "",
    fileIds: fileIds || [],
  });

  return submit;
}

export async function completeSubmission(accountId, courseId) {
  return await completeSubmissionsService.find({
    query: {
      courseId,
    },
    headers: {
      accountId,
    },
  });
}

export async function getEvaluateeCourse(accountId, courseId) {
  const data = await courseService.get(courseId, {
    headers: {
      accountId: accountId,
    },
  });
  return data;
}

export async function createCourseSubmissionEvaluations(
  courseSubmissionId,
  criteriaId,
  evaluatorId,
  comment,
  value,
  courseId
) {
  return await courseSubmissionEvaluationsService.create({
    courseSubmissionId,
    criteriaId,
    evaluatorId,
    comment,
    value,
    courseId,
  });
}

export async function getCourseSubmissionEvaluations(
  courseSubmissionId,
  evaluatorId,
  courseId
) {
  return await courseSubmissionEvaluationsService.find({
    query: {
      courseSubmissionId,
      evaluatorId,
    },
    headers: {
      courseId,
    },
  });
}

export async function patchCourseSubmissionEvaluations(
  evaluationId,
  comment,
  value
) {
  return await courseSubmissionEvaluationsService.patch(evaluationId, {
    comment,
    value,
  });
}

export async function searchTrainee(name, courseId) {
  return await courseSubmissionsService.find({
    query: {
      search: name,
    },
    headers: {
      courseId,
    },
  });
}

export async function generateExcel(courseId) {
  return await app.service("generate-excel").create({
    type: "COURSE SUMMARY",
    courseId,
  });
}
