import { getField, updateField } from "vuex-map-fields";
import {
  getUserCard,
  createUserCard,
  createSubmission,
  getSubmissions,
  patchsubmittedFiles,
  completeSubmission,
} from "../../../api";

const state = {
  introductionCardUser: [],
  finishOnBoarding: false,
  submittedFiles: null,
  editsubmittedFiles:null
};

const actions = {
  // async getUserCard({ commit }) {
  //   const data = await getUserCard();
  //   return data;
  // },
  async createUserCard({ commit }, { cardData }) {
    const data = await createUserCard(cardData);
    commit("updateField", {
      path: "introductionCardUser",
      value: data,
    });

    return data;
  },
  async getUserCard({ commit }) {
    const { data } = await getUserCard();
    if (data && data.length > 0) {
      commit("updateField", {
        path: "introductionCardUser",
        value: data,
      });
    }
    return data;
  },
  finishOnBoardingProcess({ commit }) {
    commit("updateField", {
      path: "finishOnBoarding",
      value: true,
    });
  },
  async createSubmission(
    { commit, rootState },
    { submissionStepId, text, fileIds }
  ) {
    let userId = rootState.auth.currentUser._id;

    const data = await createSubmission(
      submissionStepId,
      text,
      fileIds,
      userId
    );
    commit("updateField", {
      path: "submittedFiles",
      value: data,
    });
  },
  async getSubmissions({ rootState }, { submissionStepId }) {
  
    let submissions = await getSubmissions(
      rootState.auth.currentUser._id,
      submissionStepId
    );
    return submissions;
  }, 
  async editSubmission({commit}, {submissionStepId,text , fileIds}){
     const data = await patchsubmittedFiles(submissionStepId,text , fileIds);
    commit("updateField", {
      path: "editsubmittedFiles",
      value: data,
    });
  },
  async completeSubmission({ _ }, { courseId }) {
    return await completeSubmission(courseId);
  },
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
