import { getField, updateField } from "vuex-map-fields";
import {
  signUpUser,
  loginUser,
  logoutUser,
  askPasswordReset,
  resetPassword,
  reauth,
  currentUser,
  getAllAccounts,
  updateAccount,
  filesUpload,
  getAccount,
  getUser,
} from "../../api";

const state = {
  currentUser: null,
  accountId: undefined,
  accounts: [],
  currentAccount: null,
  accountRoles: [],
};

const actions = {
  async signUpUser({ commit, dispatch }, payload) {
    await signUpUser(payload);

    // dispatch login action
    const user = await dispatch("loginUser", payload);

    commit("updateField", {
      path: "currentUser",
      value: user,
    });

    return user;
  },

  async askPasswordReset({ _ }, { email }) {
    return await askPasswordReset(email);
  },

  async resetPassword({ _ }, { token, password }) {
    return await resetPassword(token, password);
  },

  async loginUser({ commit, dispatch }, { email, password }) {
    const user = await loginUser(email, password);
    commit("updateField", {
      path: "currentUser",
      value: user,
    });

    if (user.accounts && user.accounts.length) {
      commit("updateField", {
        path: "accountId",
        value: user.accounts[0],
      });

      let accountRoles = [];
      for (var i in user.roles) {
        accountRoles.push(user.roles[i].resourceGroups[0].name);
      }
      commit("updateField", {
        path: "accountRoles",
        value: accountRoles,
      });

      await dispatch("getUserAccounts");
      await dispatch("getCurrentAccount");
    }

    return user;
  },

  async logoutUser({ commit }) {
    await logoutUser();
    commit("updateField", {
      path: "currentUser",
      value: null,
    });

    commit("updateField", {
      path: "accountId",
      value: undefined,
    });

    return null;
  },

  async refreshCurrentUser({ commit, state }) {
    const user = await getUser(state.currentUser._id);

    commit("updateField", {
      path: "currentUser",
      value: user,
    });
  },

  async getUserAccounts({ commit }) {
    const { accounts } = await getAllAccounts();
    commit("updateField", {
      path: "accounts",
      value: accounts || [],
    });

    return accounts;
  },

  getCurrentAccount({ state, commit }) {
    const currentAccount = state.accounts.filter((item) => {
      return item._id === state.accountId;
    })[0];
    commit("updateField", {
      path: "currentAccount",
      value: currentAccount,
    });

    return currentAccount;
  },

  async updateAccount({ commit, state }, payload) {
    // first update the account
    await updateAccount(state.accountId, payload);

    // then get the new account
    const data = await getAccount(state.accountId, payload._id);

    commit("updateField", {
      path: "currentAccount",
      value: data,
    });

    return data;
  },

  async uploadAccountLogo(_, data) {
    const formData = new FormData();
    formData.append("file", data);
    const result = await filesUpload(formData);
    return result && result.length ? result[0] : null;
  },

  async reauth({ commit, dispatch }) {
    const user = await reauth();

    commit("updateField", {
      path: "currentUser",
      value: user,
    });

    if (user.accounts && user.accounts.length) {
      commit("updateField", {
        path: "accountId",
        value: user.accounts[0],
      });

      let accountRoles = [];
      for (var i in user.roles) {
        accountRoles.push(user.roles[i].resourceGroups[0].name);
      }
      commit("updateField", {
        path: "accountRoles",
        value: accountRoles,
      });

      await dispatch("getUserAccounts");
      await dispatch("getCurrentAccount");
    }

    return user;
  },
  async authenticate({ dispatch }) {
    let user = await currentUser();

    if (!user) {
      user = await dispatch("reauth");
    }

    return { user: user };
  },
};

const mutations = {
  updateField,
  verifyCurrentUser() {
    state.currentUser.isVerified = true;
  },
  beezInUser(state, value) {
    state.currentUser.rolesIds = value.rolesIds;
    state.currentUser.accounts = value.accounts;
    state.currentUser.accountModules = value.accountModules;
    if (value.trainings) {
      state.currentUser.trainings = value.trainings;
    }
    if (value.courses) {
      state.currentUser.courses = value.courses;
    }
    if (state.currentUser.roles) {
      state.currentUser.roles = value.roles;
    } else {
      state.currentUser["roles"] = value.roles;
    }

    // state.currentUser.allRoles = value.allRoles;
  },
  setAccountAfterBeezIn(state) {
    state.accountId = state.currentUser.accounts[0];

    for (var i in state.currentUser.roles) {
      state.accountRoles.splice(
        1,
        0,
        state.currentUser.roles[i].resourceGroups[0].name
      );
    }
  },
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
