<template>
  <!-- $listeners will inehrit all default listeners (click and so on) of the v-btn -->
  <!-- $attrs will inehir all default attributes like: loading, disabled, color of the b-btn -->
  <v-btn v-on="$listeners" v-bind="$attrs">
    <div class="d-flex align-center flex-grow-1" v-if="position == 'left'">
      <slot name="icon"></slot>
      <div class="d-flex justify-center flex-grow-1">
        <slot></slot>
      </div>
    </div>
    <div class="d-flex align-center flex-grow-1" v-if="position == 'right'">
      <div class="d-flex justify-center flex-grow-1">
        <slot></slot>
      </div>
      <slot name="icon"></slot>
    </div>
  </v-btn>
</template>
<style scoped>
.btn-content-width {
  width: 100% !important;
}
</style>
<script>
export default {
  name: "BzButton",
  props: ["position"],
};
</script>
