<template>
  <auth-container>
    <v-col slot="right" justify="center">
      <v-row justify="center">
        <v-img
          max-width="147"
          max-height="67"
          src="../../assets/Buro.svg"
        ></v-img>
      </v-row>
      <v-row justify="center">
        <v-card
          v-if="!resetSuccess"
          outlined
          class="mt-4 px-6 pb-4"
          width="340"
        >
          <v-form @submit.prevent="passwordReset" class="mt-4">
            <div class="d-flex self-justify-start">New Password</div>

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              name="pass"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              @blur="$v.password.$touch"
              @click:append="showPassword = !showPassword"
              required
              :error-messages="passwordErrorMessages"
            ></v-text-field>
            <v-text-field
              class="ma-0 pa-0"
              v-model="confirmPassword"
              name="pass"
              :type="showPassword ? 'text' : 'password'"
              label="Confirm Password"
              @click:append="showPassword = !showPassword"
              required
            ></v-text-field>

            <transition name="slide-fade">
              <v-alert v-if="hasError" type="error" class="caption pa-1 mt-4">
                {{ this.errorMessage }}
              </v-alert>
            </transition>
            <bz-submit-button
              :loading="loading"
              :disabled="this.$v.$invalid"
              title="Reset Password"
              svgIcon="submit_icon"
              type="Submit"
              class="full-width mt-4"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
          </v-form>
          <div class="mt-4 d-flex flex-column align-start">
            <auth-link-button
              title="Forgot Password?"
              :to="{ name: 'forgot-password' }"
            />
            <auth-link-button
              title="Not a member? Sign-up"
              :to="{ name: 'sign-up' }"
            />
          </div>
        </v-card>
        <v-card v-else outlined class="mt-4 px-6 pb-4" width="340">
          <div class="textDark--text form_width mt-6 mx-6">
            You have successfully reset your password
          </div>
          <bz-submit-button
            class="full-width mt-4"
            title="Go to Login"
            svgIcon="submit_icon"
            :to="{ name: 'log-in' }"
          >
            <v-divider vertical color="white" class="ml-2" />
          </bz-submit-button>
        </v-card>
      </v-row>
    </v-col>
  </auth-container>
</template>

<script>
import AuthContainer from "../components/AuthContainer";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import AuthLinkButton from "../components/AuthLinkButton";

export default {
  name: "ResetPasswordCard",
  components: { AuthContainer, BzSubmitButton, AuthLinkButton },
  data() {
    return {
      token: "",
      password: "",
      errorMessage: "",
      loading: false,
      showPassword: false,
      confirmPassword: "",
      resetSuccess: false
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(5)
    }
  },
  computed: {
    passwordErrorMessages() {
      const errors = [];
      if (!this.$v.password.$dirty) return [];
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password must be at least 5 characters long");
      return errors;
    },
    passwordsMatch() {
      return this.password === this.confirmPassword;
    },
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    }
  },
  methods: {
    ...mapActions("auth", {
      resetPassword: "resetPassword"
    }),

    async passwordReset() {
      if (!this.passwordsMatch) {
        this.errorMessage = "Passwords DO NOT match";
        this.password = "";
        this.confirmPassword = "";
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
        return;
      }
      this.loading = true;

      try {
        await this.resetPassword({
          token: this.token,
          password: this.password
        });
        this.resetSuccess = true;
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
      } finally {
        this.loading = false;
        this.password = "";
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  beforeMount() {
    this.token = this.$route.params.id;
  }
};
</script>
