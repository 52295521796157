<template>
  <v-snackbar
    v-model="value.show"
    :color="value.color"
    center
    top
    :timeout="value.timeout"
    ><span>
      <v-icon class="mr-2">{{ icon }}</v-icon
      >{{ value.message }}
    </span>
  </v-snackbar>
</template>
<script>
export default {
  name: "BzNotification",
  props: ["value"],
  computed: {
    icon() {
      switch (this.value.type) {
        case "error":
          return "mdi-alert-circle";
        case "succes":
          return "mdi-check-circle";
        default:
          return "mdi-information";
      }
    }
  }
};
</script>
