import { getField, updateField } from "vuex-map-fields";
import { getGalleryContent } from "../../api";

const state = {
  gallery: null
};

const actions = {
  async getGalleryContent({ commit }) {
    const data = await getGalleryContent();
    commit("updateField", {
      path: "gallery",
      value: data.data
    });
    return data;
  }
};
const mutations = {
  updateField
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
