import { getField, updateField } from "vuex-map-fields";
import {
  getEvaluatorTraining,
  getSubmissionData,
  getComments,
  addComments,
  createCourseSubmissionEvaluations,
  getCriteria,
  patchCourseSubmissionEvaluations,
  getCourseSubmissionEvaluations,
  searchTrainee,
} from "../../../api";

const state = {
  evaluatorTrainings: [],
  Trainees: [],
  storedFileTypes: [],
  submissionEvaluator: [],
  traineeSubmission: [],
  currentCourseId: null,
  courseCriteria: [],
};

const actions = {
  storeFileTypes({ commit }, fileTypes) {
    commit("updateField", {
      path: "storedFileTypes",
      value: fileTypes,
    });
  },
  storeTraineeSubmission({ commit }, Submission) {
    commit("updateField", {
      path: "traineeSubmission",
      value: Submission,
    });
  },
  async getComments({ rootState, commit, state }, { submissionId }) {
    const { _id } = rootState.auth.currentUser;
    // console.log(state.traineeSubmission);
    let userSubmissionId;
    for (var i in state.traineeSubmission) {
      if (state.traineeSubmission[i].submissionStepId == submissionId) {
        userSubmissionId = state.traineeSubmission[i]._id;
      }
    }
    const { data } = await getComments(_id, userSubmissionId);
    // console.log(data);
    commit("updateField", {
      path: "submissionEvaluator",
      value: data,
    });
    return data;
  },
  async addComments({ state }, { submissionId, comment }) {
    let userSubmissionId;
    for (var i in state.traineeSubmission) {
      if (state.traineeSubmission[i].submissionStepId == submissionId) {
        userSubmissionId = state.traineeSubmission[i]._id;
      }
    }
    return await addComments(userSubmissionId, comment);
  },

  async getEvaluatorTraining({ commit, rootState }) {
    const { accountId } = rootState.auth;
    const { courses } = rootState.auth.currentUser;
    const { data } = await getEvaluatorTraining(accountId, courses);
    commit("updateField", {
      path: "evaluatorTrainings",
      value: data,
    });
    return data;
  },

  async getSubmissionFiles({ commit, rootState }, { courseId, limit, skip }) {
    const { accountId } = rootState.auth;
    const { data } = await getSubmissionData(accountId, courseId, limit, skip);
    commit("updateField", {
      path: "Trainees",
      value: data,
    });
    return data;
  },
  storeCourseId({ commit }, { courseId }) {
    commit("updateField", {
      path: "currentCourseId",
      value: courseId,
    });
  },
  async createCourseSubmissionEvaluations(
    { rootState, state },
    { courseSubmissionId, criteriaId, comment, value }
  ) {
    const evaluatorId = rootState.auth.currentUser._id;
    const courseId = state.currentCourseId;
    return await createCourseSubmissionEvaluations(
      courseSubmissionId,
      criteriaId,
      evaluatorId,
      comment,
      value,
      courseId
    );
  },
  async getCourseSubmissionEvaluations(
    { rootState, state },
    { courseSubmissionId }
  ) {
    const evaluatorId = rootState.auth.currentUser._id;
    const courseId = state.currentCourseId;
    const { data } = await getCourseSubmissionEvaluations(
      courseSubmissionId,
      evaluatorId,
      courseId
    );
    return data;
  },
  async getCriteria({ state, commit }) {
    let courseId = state.currentCourseId;
    let criteriaIds = [];
    for (var i in state.evaluatorTrainings) {
      if (state.evaluatorTrainings[i].courseIds.includes(courseId)) {
        for (var j in state.evaluatorTrainings[i].courses) {
          if (courseId == state.evaluatorTrainings[i].courses[j]._id) {
            criteriaIds = state.evaluatorTrainings[i].courses[j].criteriaIds;
            break;
          } else {
            continue;
          }
        }
      } else {
        continue;
      }
    }
    const { data } = await getCriteria(criteriaIds, courseId);
    // console.log(data);
    commit("updateField", {
      path: "courseCriteria",
      value: data,
    });
    return data;
  },
  async patchCourseSubmissionEvaluations(
    { _ },
    { evaluationId, comment, value }
  ) {
    const data = await patchCourseSubmissionEvaluations(
      evaluationId,
      comment,
      value
    );
    return data;
  },
  async searchTrainee({ state }, { name }) {
    let courseId = state.currentCourseId;
    const { data } = await searchTrainee(name, courseId);
    return data;
  },
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
