<template>
  <bz-button
    @click="onSubmit"
    :color="color"
    showDivider
    v-on="$listeners"
    v-bind="$attrs"
    :position="position"
    class="text-capitalize"
  >
    <div slot="icon" class="d-flex">
      <svgicon
        width="18"
        height="18"
        color="white"
        :icon="svgIcon"
        v-if="svgIcon"
      ></svgicon>
      <v-icon size="18" color="white" slot="icon" v-if="icon">{{
        icon
      }}</v-icon>
      <slot></slot>
      <!-- <v-divider vertical color="white" class="ml-2" /> -->
      <!-- <v-divider vertical color="white" class="ml-4 mr-6" /> -->
    </div>

    <div class="d-flex textLight--text">
      <span class="justify-center">{{ title }}</span>
    </div>
    </bz-button>
</template>

<script>
import BzButton from "./BzButton";
import { eventBus } from "../../main";

export default {
  components: {
    BzButton,
  },
  name: "BzSubmitButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    svgIcon: {
      type: String,
      required: false,
    },
    submitTo: {
      type: String,
      required: false,
      default: null,
    },
    color: {
      type: String,
      required: false,
      default: "textOrange",
    },
    position: {
      type: String,
      required: false,
      default: "left",
    },
  },
  methods: {
    onSubmit() {
      if (this.submitTo !== null) {
        eventBus.$emit(this.submitTo);
      }
    },
  },
};
</script>
