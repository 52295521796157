<template>
  <v-container class="ma-0 pa-0">
    <v-row class="mx-0 my-4 pa-0">
      <!-- max-height="416" -->
      <v-col class="mr-6 pa-0">
        <p class="mb-2 pa-0">INTRODUCTION BEEZCARD</p>
        <v-card width="655" flat color="transparent">
          <v-row class="ma-0 pa-0">
            <v-col class="pa-0 mb-2 ma-0" sm="12" md="6" lg="6">
              <v-card
                flat
                class="card-shadow ma-0 d-flex pa-0 flex-column align-center justify-center"
                width="345"
                height="460"
              >
                <v-col class="ma-0 pa-0">
                  <div class="ma-0 pa-0">
                    <v-row class="secondaryText ma-0 pa-0">
                      <v-col
                        class="ml-8 mt-8 pa-0 d-flex flex-column align-self-start text-light"
                        lg="3"
                        md="3"
                        cols="8"
                      >
                        <v-img class="pa-0" src="../../../../../assets/add_image_black.svg"></v-img>
                      </v-col>

                      <v-col
                        lg="8"
                        md="8"
                        cols="12"
                        class="ma-0 ml-8 pa-0 pt-2 mb-8 textLight--text d-flex flex-column align-self-start"
                      >
                        PHOTO OF USER
                        <br />GOES HERE
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mx-0 my-6 pa-0">
                    <v-row class="mx-3 pa-0 my-0">
                      <v-col cols="12" class="secondaryText--text pt-6 px-2">FULL NAME</v-col>
                      <v-col cols="12" class="d-flex flex-row pa-0 mx-0 mt-2 mb-0">
                        <v-text-field
                          v-model="jobTitle"
                          :maxlength="maxJobTitle"
                          :counter="maxJobTitle"
                          class="mx-2 my-0 pa-0"
                          placeholder="Job Title / Position"
                          @input="onChangeField('jobTitle', jobTitle)"
                        ></v-text-field>

                        <v-icon left>mdi-pencil</v-icon>
                      </v-col>
                      <v-col cols="12" class="d-flex flex-row pa-0 mx-0 mt-0 mb-8">
                        <v-text-field
                          v-model="aboutTitle"
                          class="my-0 mx-2 pa-0"
                          placeholder="About"
                          :maxlength="maxAbout"
                          :counter="maxAbout"
                          @input="onChangeField('aboutTitle', aboutTitle)"
                        ></v-text-field>
                        <v-icon left>mdi-pencil</v-icon>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-card>
            </v-col>
            <v-col class="pa-0 ma-0" sm="12" md="6" lg="6">
              <v-card
                flat
                class="card-shadow d-flex align-center justify-center flex-column ma-0 px-0 py-2"
                width="310"
                height="460"
              >
                <div class="ma-0 pa-0">
                  <v-col cols="12" class="d-flex flex-row ma-0 pa-0">
                    <v-text-field
                      v-model="section0title"
                      class="my-0 mx-2 pa-0"
                      placeholder="Expertise"
                      :maxlength="15"
                      :counter="15"
                      @input="onChangeField('section0', section0title)"
                    ></v-text-field>
                    <v-icon left>mdi-pencil</v-icon>
                  </v-col>
                  <v-col cols="12" class="ma-0 pa-0">
                    <bz-tags
                      class="my-2 pa-0"
                      darkColor="primaryLight"
                      lightColor="lightBlue"
                      textColor="primaryLight--text"
                      :listOfTags="expertise"
                    />
                  </v-col>
                  <v-col cols="12" class="d-flex flex-row ma-0 pa-0">
                    <v-text-field
                      v-model="section1title"
                      class="my-0 mx-2 pa-0"
                      placeholder="Projects"
                      :maxlength="15"
                      :counter="15"
                      @input="onChangeField('section1', section1title)"
                    ></v-text-field>
                    <v-icon left>mdi-pencil</v-icon>
                  </v-col>
                  <v-col sm="12" class="ma-0 pa-0">
                    <bz-tags
                      class="my-2 pa-0"
                      darkColor="textOrange"
                      lightColor="lightOrange"
                      textColor="textOrange--text"
                      :listOfTags="projects"
                    />
                  </v-col>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col class="ma-0 pa-0">
        <p class="mb-2 pa-0">PREVIEW BEEZCARD</p>
        <IntroductionBeeZCardPreview
          :jobTitle="jobTitle"
          :aboutTitle="aboutTitle"
          :section0="section0title"
          :section1="section1title"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BzTags from "@shared/components/BzTags";
import IntroductionBeeZCardPreview from "./IntroductionBeeZCardPreview";
import { required } from "vuelidate/lib/validators";

export default {
  name: "IntroductionBeeZCardTemplate",
  data() {
    return {
      maxJobTitle: 30,
      maxAbout: 40,
      // aboutTitle: "",
      projects: ["Projects Tag #1", "Projects Tag #2", "Projects Tag #3"],
      expertise: ["Expertise Tag #1", "Expertise Tag #2", "Expertise Tag #3"],
      section0title: "",
      section1title: ""
      // sections: []
    };
  },
  props: {
    jobTitle: {
      type: String,
      required: true
    },
    aboutTitle: {
      type: String,
      required: true
    },
    sections: {
      type: Array,
      required: true
    }
  },
  validations: {
    jobTitle: {
      required
    },
    aboutTitle: {
      required
    },
    section0title: {
      required
    },
    section1title: {
      required
    }
  },
  components: {
    BzTags,
    IntroductionBeeZCardPreview
  },
  created() {
    this.setFields();
  },
  watch: {
    $v: {
      deep: true,
      handler(value) {
        // console.log(value.$invalid);
        this.$emit("formInvalid", value.$invalid);
      }
    }
    // this.snackbar = true;
  },
  methods: {
    onChangeField(tag, value) {
      let fieldObject;
      switch (tag) {
        case "jobTitle":
          fieldObject = {
            jobTitle: value
          };
          break;
        case "aboutTitle":
          fieldObject = {
            aboutTitle: value
          };
          break;

        case "section0":
          fieldObject = {
            section0: value
          };
          break;

        case "section1":
          fieldObject = {
            section1: value
          };
          break;
      }
      this.$emit("changeField", fieldObject);
    },
    setFields() {
      if (this.sections.length > 0) {
        this.section0title = this.sections[0].title;
        this.section1title = this.sections[1].title;
      }
    }
  }
};
</script>
<style scoped>
.bg-image-container {
  background: #a1a1a1 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}
.card-shadow {
  box-shadow: 0px 0px 75px #00000024;
}
</style>
