<template>
  <v-row justify="start" :class="{scrollableContainer:isSmallSize}">
    <!-- dialog -->
    <div class="d-flex justify-content-start">
      <v-dialog v-model="featureAdminDialog">
        <v-container fluid class="white">
          <UsersTable
            :hideFooter="false"
            :isList="false"
            :isDialog="true"
            :isUser="false"
            :isPush="false"
            @addFeatureAdminDialog="openAddFeatureAdminDialog"
            @saveFeatureAdminDialog="closeAddFeatureAdminDialog"
            @successMessageEvent="showSuccessMessage"
          />
        </v-container>
      </v-dialog>
    </div>

    <v-row justify="start">
      <UsersTable
        :hideFooter="false"
        :isList="true"
        :isDialog="false"
        :isUser="false"
        :isPush="false"
        @addFeatureAdminDialog="openAddFeatureAdminDialog"
      />
    </v-row>
    <BzNotification v-model="notification"></BzNotification>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import UsersTable from "@/tables/UsersTable";
import BzNotification from "@/shared/components/BzNotification";
export default {
  name: "Announcement",
  components: {
    UsersTable,
    BzNotification
  },
  data() {
    return {
      featureAdminDialog: false,
      dialogWidth: 1413,
      isSmallSize: false,
      window: {
        width: 0,
        height: 0
      }
    };
  },
  created() {
    window.addEventListener("resize", this.handleScroll);
    this.handleScroll();
  },
  methods: {
    openAddFeatureAdminDialog() {
      this.featureAdminDialog = true;
      // fetch the account module
    },
    closeAddFeatureAdminDialog() {
      this.featureAdminDialog = false;
      this.notify("info", "Saving as Feature Admin");
    },
    showSuccessMessage() {
      this.notify("success", "Successfully Saved as Feature Admin");
    },
    handleScroll() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 1099) {
        this.isSmallSize = true;
      } else {
        this.isSmallSize = false;
      }
    }
  }
};
</script>
 
  <style scoped>
.scrollableContainer {
  overflow-x: scroll;
}
</style>