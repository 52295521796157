import { getField, updateField } from "vuex-map-fields";
import {
    findTrainings,
    getTraining,
    updateStepFilesSubmission,
    getCourse,
    getSubmissionForSteps,
    createStepSubmission,
    filesUpload,
    getFile,
    updateStepSubmission,
    completeSubmission,
    getEvaluateeCourse
} from "../../../api";

const state = {
    trainings: [],
    selectedTraining: null,
    selectedCourse: null,
    userSubmissions: []
};


const actions = {
    async getTrainings({ commit, rootState }, ids) {

        const { accountId } = rootState.auth;

        const { data } = await findTrainings(accountId, ids);

        commit("updateField", {
            path: "trainings",
            value: data
        });

        return data;
    },

    setSelectedTraining({ commit }, training) {
        commit("updateField", {
            path: "selectedTraining",
            value: training
        });
    },


    setSelectedCourse({ commit }, course) {
        commit("updateField", {
            path: "selectedCourse",
            value: course
        });
    },

    async getTraining({ commit, rootState, state }, id) {

        const { accountId } = rootState.auth;

        const data = await getTraining(accountId, id);

        commit("updateField", {
            path: "selectedTraining",
            value: data
        });

        return data;
    },

    async getCourse({ rootState, commit }, id) {
        const { accountId } = rootState.auth;
        const data = await getCourse(accountId, id);

        commit("updateField", {
            path: "selectedCourse",
            value: data
        });

        return data;
    },

    async getSubmissionsForSteps({ rootState, commit }, stepsIds) {
        const { _id } = rootState.auth.currentUser;
        const { accountId } = rootState.auth;

        const { data } = await getSubmissionForSteps(accountId, _id, stepsIds);

        commit("updateField", {
            path: "userSubmissions",
            value: data
        });

        return data;
    },

    async getStepSubmissionFiles(_, fileIds) {

        const promises = [];

        fileIds.forEach(element => {
            promises.push(getFile(element));
        });

        return await Promise.all(promises);

    },

    async updateStepFilesSubmission({ rootState, state, commit }, { stepId, filesData }) {

        const { accountId } = rootState.auth;

        const filesUploadPromise = filesData.map(data => {
            const formData = new FormData();
            formData.append("file", data);
            return filesUpload(formData);
        });


        const files = await Promise.all(filesUploadPromise);

        const fileIds = files.map(file => {
            return file[0]._id;
        });

        const submission = await updateStepFilesSubmission(accountId, stepId, fileIds);

        const submissions = [...state.userSubmissions];

        // find the current submission in the submissions array 
        const oldSubmission = submissions.filter(item => item._id === stepId)[0];
        const oldSubmissionIndex = submissions.indexOf(oldSubmission);

        // replace old submission with new one
        submissions.splice(oldSubmissionIndex, 1, submission);

        // update state
        commit("updateField", {
            path: "userSubmissions",
            value: submissions
        });

        return submission;

    },

    async uploadFilesForSubmission(_, filesData) {
        const filesUploadPromise = filesData.map(data => {
            const formData = new FormData();
            formData.append("file", data);
            return filesUpload(formData);
        });


        const files = await Promise.all(filesUploadPromise);

        return files.map(file => {
            return file[0];
        });
    },


    async createStepFilesSubmission({ rootState, state, commit }, { submissionStepId, filesData }) {

        const { accountId } = rootState.auth;
        const { _id } = rootState.auth.currentUser;


        const filesUploadPromise = filesData.map(data => {
            const formData = new FormData();
            formData.append("file", data);
            return filesUpload(formData);
        });

        const files = await Promise.all(filesUploadPromise);

        const fileIds = files.map(file => {
            return file[0]._id;
        });

        const submission = await createStepSubmission(accountId, {
            submissionStepId,
            fileIds,
            userId: _id
        });

        const submissions = [...state.userSubmissions];
        submissions.push(submission);

        commit("updateField", {
            path: "userSubmissions",
            value: submissions
        });

        return submission;
    },

    async updateStepSubmission({ rootState, state, commit }, payload) {

        const { accountId } = rootState.auth;

        const submission = await updateStepSubmission(accountId, payload);

        const submissions = [...state.userSubmissions];

        // find the current submission in the submissions array 
        const oldSubmission = submissions.filter(item => item._id === payload._id)[0];
        const oldSubmissionIndex = submissions.indexOf(oldSubmission);

        // replace old submission with new one
        submissions.splice(oldSubmissionIndex, 1, submission);

        // update state
        commit("updateField", {
            path: "userSubmissions",
            value: submissions
        });

        return submission;
    },

    async createStepSubmission({ rootState, state, commit }, { submissionStepId, text }) {

        const { accountId } = rootState.auth;
        const { _id } = rootState.auth.currentUser;

        const submission = await createStepSubmission(accountId, {
            submissionStepId,
            text,
            userId: _id
        });

        const submissions = [...state.userSubmissions];
        submissions.push(submission);

        commit("updateField", {
            path: "userSubmissions",
            value: submissions
        });

        return submission;
    },
    async completeSubmission({ rootState, dispatch, state }, courseId) {
        const { accountId } = rootState.auth;
        await completeSubmission(accountId, courseId);
        await dispatch("getSubmissionsForSteps", state.selectedCourse.submissionStepIds);
    },

    /**Workaround Temporary */ 
    async getEvaluateeCourse({ _ , rootState}, { courseId }) {
         const { accountId } = rootState.auth;
        const course = await getEvaluateeCourse(accountId, courseId);
        return course;
      },
};

const mutations = {
    updateField
};

const getters = {
    getField
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}