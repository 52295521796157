<template>
  <v-row no-gutters v-if="pageRender">
    <v-row v-if="isList && !isUser" justify="end">
      <bz-button
        width="230"
        icon="mdi-plus"
        @click="submitToEventPicker(featureAdminIndex)"
        title="Add Feature Admin"
        type="Submit"
        :loading="featureAdminLoading"
        depressed
        class="mt-2 mr-12"
      ></bz-button>
    </v-row>

    <v-container v-if="isUser" class="btn-padding d-flex justify-end mx-0 pa-0 mt-0 mb-2">
      <bz-button
        @click="submitToEventPicker(inviteUserIndex)"
        :submit-to="submitTo"
        title="Invite User"
        type="Submit"
        depressed
      ></bz-button>
    </v-container>
    <!--  -->
    <v-container class="d-flex justify-start ma-0 pa-0">
      <v-col v-if="isList?colNumber=10:colNumber=12" :cols="12" class="ma-0 pa-0">
        <v-container
          v-if="headersToDisplay.length !== 0"
          fluid
          class="ma-0 pa-0"
          :class="{dataTableListContainer:isList,dataTableDialogContainer: isDialog}"
        >
          <v-row v-if="isDialog" justify="center">
            <label class="add-feature-admin-label">Add Feature Admin</label>
          </v-row>
          <v-divider v-if="isDialog"></v-divider>
          <!-- <v-text-field
            v-if="users.length !== 0"
            v-show="(isList)?searchUsersLabel=adminUsers:searchUsersLabel=searchUsers"
            v-model="search"
            append-icon="mdi-magnify"
            :label="searchUsersLabel"
            single-line
            hide-details
            class="mx-0 pa-0"
          ></v-text-field>-->
          <v-data-table
            v-if="users.length!==0"
            :headers="headersToDisplay"
            :items="users"
            :items-per-page="itemsPerPage"
            :hide-default-footer="hideFooter"
            no-data-text=" "
            :mobile-breakpoint="mobileBreakPoint"
            class="box-shadow-datatable"
          >
            <!-- Customizing the DataTable  -->
            <template v-slot:item.profile="{ item }">
              <v-avatar color="primaryLight" size="38" class="avatar-style">
                <span class="white--text headline">{{ item.profile }}</span>
              </v-avatar>
            </template>
            <!-- roles decrease size+ -->
            <template v-slot:item.role="{ item }">
              <label
                class="text-lowercase textDark--text text-center font-weight-regular"
              >{{ item.role }}</label>
            </template>

            <template v-slot:item.status="{ item }">
              <v-row v-if="isDialog">
                <v-col v-if="item.checked===true">
                  <v-checkbox v-model="item.checked"></v-checkbox>
                </v-col>
                <v-col v-if="item.checked===false">
                  <v-checkbox v-model="item.checked"></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-if="isUser  && item.status !== 'ACTIVE'">
                <v-col cols="12">
                  <div class="d-flex flex-row">
                    <v-btn x-small color=" secondaryText" class="mx-2">
                      <label
                        class="text-uppercase white--text text-center font-regular overline"
                      >{{ 'pending' }}</label>
                    </v-btn>
                    <v-btn
                      :loading="resendLoading"
                      x-small
                      color="textOrange"
                      @click="resendInvite(item.email)"
                    >
                      <label
                        class="text-uppercase white--text text-center font-regular overline"
                      >{{ 'Resend Invite' }}</label>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-data-table>

          <v-row v-if="isDialog && users.length!==0" justify="center">
            <bz-submit-button
              @click="submitToEventPicker(saveAdminIndex)"
              :loading="featureAdminLoading"
              :submit-to="submitTo"
              icon="mdi-content-save"
              width="350"
              title="Save"
              type="Submit"
              class="full-width mt-4 pa-2"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
          </v-row>

          <!-- condition for No user with ; but Dialog is Open  -->
        </v-container>
        <v-container
          v-if="users.length===0"
          fluid
          class="ma-0 pa-0"
          :class="{dataTableListContainer:isList,dataTableDialogContainer: isDialog}"
        >
          <!-- <v-text-field append-icon="mdi-magnify" :label="searchUsers" single-line hide-details class="pa-2"></v-text-field> -->

          <!-- <v-row row wrap v-if="isList===true">
            <v-col cols="12" sm="12" md="12" lg="5" v-for="(item,i) in listHeaders" :key="i" xs-2>
              <label class="ml-12 pl-12 mr-12">{{item.text}}</label>
            </v-col>
          </v-row>-->

          <div v-if="isList === true" class="pa-3 d-flex align-center justify-space-between mx-10">
            <div
              v-for="(item,i) in listHeaders"
              :key="i"
              class="mx-4 my-0 pa-0 translucentText"
            >{{item.text}}</div>
          </div>

          <v-row row wrap v-if="isList===false">
            <v-col cols="12" sm="12" md="12" lg="3" v-for="(item,i) in headers" :key="i" xs-2>
              <label class="ml-12 pl-12 mr-12">{{item.text}}</label>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row v-if="isDialog && users.length === 0 " justify="center">
            <label class="display-3">No Users Found !</label>
          </v-row>

          <v-row v-if="isList && !isUser" justify="center" class="pt-12 mt-12 pb-12">
            <div class="text-wrap">
              <label
                class="text-uppercase secondaryText--text text-center d-block font-weight-thin body-1"
              >There are</label>
              <label
                class="texfetchUsersServicet-uppercase text-center d-block font-weight-bold body-1"
              >No Feature Admins</label>
              <label
                class="text-uppercase secondaryText--text text-center d-block font-weight-thin body-1"
              >in your Account</label>
              <label
                class="text-uppercase secondaryText--text text-center d-block font-weight-light overline pt-12 pb-6"
              >You can add an Admin Here</label>
              <v-row justify="center">
                <bz-submit-button
                  @click="submitToEventPicker(featureAdminIndex)"
                  :loading="featureAdminLoading"
                  :submit-to="submitTo"
                  icon="mdi-plus"
                  title="Add Feature Admin"
                  type="Submit"
                  class="full-width mt-4 pa-2"
                ></bz-submit-button>
              </v-row>
            </div>
          </v-row>
          <v-row v-if="isUser" justify="center" align="center" class="my-12 pb-12">
            <div class="text-wrap">
              <label
                class="text-uppercase secondaryText--text text-center d-block title py-0 my-0 translucentText"
              >There are</label>
              <label
                class="text-uppercase text-center d-block font-weight-bold headline py-0 my-0 translucentText"
              >No Other Members</label>
              <label
                class="text-uppercase secondaryText--text text-center d-block title py-0 my-0 translucentText"
              >Added To Your</label>
              <label
                class="text-uppercase secondaryText--text text-center d-block title py-0 my-0 translucentText"
              >Account</label>
              <label
                class="secondaryText--text text-center d-block font-weight-regular caption pa-0 ma-0"
              >You can invite a member here</label>
              <v-row justify="center">
                <bz-submit-button
                  @click="submitToEventPicker(inviteUserIndex)"
                  :submit-to="submitTo"
                  title="Invite User"
                  depressed
                  type="Submit"
                  class="full-width mt-2"
                >
                  <v-divider vertical color="white" class="ml-2" />
                </bz-submit-button>
              </v-row>
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-container>
    <BzNotification v-model="notification"></BzNotification>
  </v-row>
  <div v-else class="d-flex justify-center align-center" style="height: 60vh;width: 110vh;">
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { roles } from "../shared/constants/roles";
import { levels } from "../shared/constants/levels";
import { permissions } from "../shared/constants/permissions";
// import { Current_Account_ID } from "../api";
import { eventBus } from "../main";
import BzButton from "@shared/components/BzSubmitButton";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import BzNotification from "@/shared/components/BzNotification";
export default {
  name: "UsersTable",
  data() {
    return {
      submitTo: "",
      inviteUserIndex: 0,
      featureAdminIndex: 1,
      saveAdminIndex: 2,
      users: [],
      itemsPerPage: 5,
      pageRender: false,
      resendLoading: false,
      search: "",
      colNumber: 12,
      currentAccountId: "",
      footer: false,
      headers: [
        { text: "Select" },
        { text: "Name" },
        { text: "Email Address" },
        { text: "Role" }
      ],
      listHeaders: [
        { text: "Name" },
        { text: "Email Address" },
        { text: "Role" },
        { text: "Status" }
      ],
      headersToDisplay: [],
      searchUsers: "Search Users",
      adminUsers: "Search Feature Admins Users",
      checkbox: false,
      featureAdminLoading: false,
      permittedMembers: [],
      mobileBreakPoint: 800
    };
  },
  components: {
    BzButton,
    BzSubmitButton,
    BzNotification
  },
  props: {
    hideFooter: {
      type: Boolean,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    },
    isList: {
      type: Boolean,
      required: true
    },
    isDialog: {
      type: Boolean,
      required: true
    },
    isPush: {
      type: Boolean
    }
  },

  async created() {
    this.currentAccountId = this.currentUser.accounts[0];
    this.headersToShow();
    await this.getAllAccountUsers();
    // await this.getAllAccountModuleMembers();
    eventBus.$on("updateUserInviteListEvent", invitedUser => {
      this.updateUsersList(invitedUser);
    });
    this.pageRender = true;
  },

  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("users", ["allAccountUsers"]),
    ...mapFields("modules", ["currentModuleMembers", "currentAccountModuleId"])
  },

  methods: {
    ...mapActions("users", {
      fetchAllAccountUsers: "fetchAllAccountUsers",
      inviteNewAccountOwner: "inviteNewAccountOwner"
    }),
    ...mapActions("modules", {
      assignCommunicationPermission: "assignCommunicationPermission",
      fetchAccountModuleMembers: "fetchAccountModuleMembers",
      storeRefreshFromLink: "storeRefreshFromLink"
    }),
    permissionSetter() {
      this.featureAdminLoading = true;
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].checked) {
          this.permittedMembers.push(this.users[i].userid);
        }
      }

      this.setCommunicationPermission();
    },
    submitToEventPicker(eventIndex) {
      switch (eventIndex) {
        case this.inviteUserIndex:
          this.$emit("inviteUserDialogEvent");
          break;
        case this.featureAdminIndex:
          this.$emit("addFeatureAdminDialog");
          break;
        case this.saveAdminIndex:
          this.permissionSetter();
          // call the Set Permission fun.
          break;
        default:
          this.notify("error", " Please select your Process!");
      }
    },
    async getAllAccountModuleMembers() {
      if (
        (!this.currentAccountModuleId ||
          this.currentAccountModuleId == "" ||
          this.currentAccountModuleId === null) &&
        this.isUser === false &&
        this.isList === true
      ) {
        this.$emit("fetchModuleIdEvent", true);
        if (this.isPush) {
          this.storeRefreshFromLink({ Link: "push-notification" });
        } else {
          this.storeRefreshFromLink({ Link: "announcement" });
        }

        this.$router.push({ name: "communication-module" });
      } else {
        try {
          const members = await this.fetchAccountModuleMembers({
            level: levels.MODULE,
            accountid: this.currentAccountId,
            accountmoduleid: this.currentAccountModuleId
          });

          this.listAllModuleMembers();
        } catch (error) {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 2000);
        }
      }
    },
    async setCommunicationPermission() {
      /**set permission */

      let permission;
      if (this.isPush) {
        permission = permissions.PUSH;
      } else {
        permission = permissions.ANNOUNCEMENT;
      }
      this.$emit("saveFeatureAdminDialog");

      try {
        const permit = await this.assignCommunicationPermission({
          adminIds: this.permittedMembers,
          accountId: this.currentAccountId,
          permission: permission,
          accountModuleId: this.currentAccountModuleId
        });
        this.$emit("successMessageEvent");
        setTimeout(() => {
          location.reload();
        }, 2000);
      } catch (error) {
        this.$emit("addFeatureAdminDialog");
        this.notify("error", error.message);
        this.featureAdminLoading = false;
      } finally {
        this.featureAdminLoading = false;
      }
    },

    updateUsersList(invitedUser) {
      /*Push the Pending User as soon as Invite is sent out */
      const fetchedUsers = {
        profile: "",
        user: "",
        email: "",
        role: "",
        status: ""
      };
      /*Profile Initials */
      fetchedUsers["profile"] = invitedUser.email.charAt(0);

      /*Full Name  */
      fetchedUsers["user"] = invitedUser.email.split("@")[0];

      /* Email  */

      fetchedUsers["email"] = invitedUser.email;

      /*Role  */

      fetchedUsers["role"] = "PENDING";

      /*Status Initials */
      fetchedUsers["status"] = "PENDING";
      this.users.splice(0, 0, fetchedUsers);
      this.notify(
        "success",
        "You have successfully invited an Account Owner",
        3000
      );
    },
    async resendInvite(Email) {
      // this.notify("info", "Resending Invitation!");
      this.resendLoading = true;
      try {
        const reInvited = await this.inviteNewAccountOwner({
          email: Email,
          accountId: this.currentAccountId,
          assignedRole: roles.ACCOUNT_OWNER
        });
        this.notify("success", "Resending Invitation Successful!");
      } catch (error) {
        // this.notify("error", error.message + "\t Resending Failed!");
      } finally {
        this.resendLoading = false;
      }
    },
    /** */
    headersToShow() {
      if (this.isList === true && this.isUser === false) {
        this.headersToDisplay.splice(
          0,
          0,
          {
            text: "",
            align: "left",
            sortable: false,
            value: "profile",
            width: "50"
          },
          { text: "Name", align: "left", sortable: false, value: "user" },
          { text: "Email Address", value: "email", sortable: false }
        );
      }
      if (this.isUser === true) {
        this.headersToDisplay.splice(
          0,
          0,
          {
            text: "",
            align: "left",
            sortable: false,
            value: "profile",
            width: "50"
          },
          {
            text: "Name",
            align: "left",
            sortable: false,
            value: "user"
          },
          {
            text: "Email Address",
            value: "email",
            sortable: false,
            width: "300"
          },
          { text: "Role", value: "role", sortable: false, width: "300" },
          { text: "Status", value: "status", sortable: false }
        );
      }
      if (this.isDialog === true) {
        this.headersToDisplay.splice(
          0,
          0,
          { text: "Select", value: "status", sortable: false, width: 10 },
          { text: "", sortable: false, value: "profile" },
          { text: "Name", value: "user", sortable: false },
          { text: "Email Address", value: "email", sortable: false },
          { text: "Role", value: "role", sortable: false }
        );
      }
    },
    async getAllAccountUsers() {
      if (this.isUser === true) {
        try {
          const allUsers = await this.fetchAllAccountUsers({
            level: levels.ACCOUNT,
            accountid: this.currentAccountId
          });
          // console.log(allUsers);
          /* Construct an array to Display for the Datatable*/
          for (
            var index = 0;
            index < allUsers.data.length;
            index++
          ) {
            if (allUsers.data[index]._id == this.currentUser._id){
              continue;
            }
            const fetchedUsers = {
              profile: "",
              user: "",
              email: "",
              role: "",
              status: ""
            };

            /*Profile Initials */

            if (
              !allUsers.data[index].firstName &&
              !allUsers.data[index].lastName
            ) {
              fetchedUsers["profile"] = allUsers.data[
                index
              ].email.charAt(0);
              fetchedUsers["user"] = "NO NAME";
            } else {
              fetchedUsers["profile"] =
                allUsers.data[index].firstName.charAt(0) +
                allUsers.data[index].lastName.charAt(0);

              /*Full Name  */
              fetchedUsers["user"] =
                allUsers.data[index].firstName +
                " " +
                allUsers.data[index].lastName;
            }
            /* Email  */

            fetchedUsers["email"] = allUsers.data[index].email;

            /*Role */
            // console.log(allUsers.data[index].roles[0]);
            if (
              allUsers.data[index].roles[0].resourceGroups[0]
                .name === roles.ACCOUNT_OWNER
            ) {
              fetchedUsers["role"] = "ACCOUNT OWNER";
            } else {
              fetchedUsers["role"] = "ACCOUNT MEMBER";
            }

            /*Status Initials */
            fetchedUsers["status"] = "ACTIVE";
            this.users.splice(0, 0, fetchedUsers);
          }

          /*For Pending Users */
          for (
            var index = 0;
            index < allUsers.pending.length;
            index++
          ) {
            const fetchedUsers = {
              profile: "",
              user: "",
              email: "",
              role: "",
              status: ""
            };

            /*Profile Initials */
            fetchedUsers["profile"] = allUsers.pending[
              index
            ].email.charAt(0);

            fetchedUsers["user"] = "NO NAME";

            /* Email  */

            fetchedUsers["email"] = allUsers.pending[index].email;

            /*Role  */

            fetchedUsers["role"] = "PENDING";

            /*Status Initials */
            fetchedUsers["status"] = "PENDING";
            this.users.splice(
              allUsers.data.length,
              0,
              fetchedUsers
            );
          }
        } catch (error) {
          this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = "";
          }, 2000);
        }
      }
    },

    /*Only Module Members to be displayed */

    async listAllModuleMembers() {
      if (
        this.currentModuleMembers !== null &&
        this.isList === true &&
        this.isDialog === false
      ) {
        let permissionArgument = "";
        if (this.isPush) {
          permissionArgument = permissions.PUSH;
        } else {
          permissionArgument = permissions.ANNOUNCEMENT;
        }
        for (
          var index = 0;
          index < this.currentModuleMembers.data.length;
          index++
        ) {
          const fetchedUsers = {
            profile: "",
            user: "",
            email: ""
          };

          const permission = this.currentModuleMembers.data[
            index
          ].currentAccountRole.moduleRoles.filter(perm => {
            /* Check if the User has the Proper Permission before pushing into the Table */
            if (
              perm.modulePermissions.find(p => p === permissionArgument) ===
              permissionArgument
            ) {
              if (
                !this.currentModuleMembers.data[index].firstName &&
                !this.currentModuleMembers.data[index].lastName
              ) {
                fetchedUsers["profile"] = this.currentModuleMembers.data[
                  index
                ].email.charAt(0);

                fetchedUsers["user"] = "NO NAME";
              } else {
                fetchedUsers["profile"] =
                  this.currentModuleMembers.data[index].firstName.charAt(0) +
                  this.currentModuleMembers.data[index].lastName.charAt(0);

                /*Full Name  */
                fetchedUsers["user"] =
                  this.currentModuleMembers.data[index].firstName +
                  " " +
                  this.currentModuleMembers.data[index].lastName;
              }

              /*Email */
              fetchedUsers["email"] = this.currentModuleMembers.data[
                index
              ].email;

              /* Set the Admins only to be displayed on the Datatable */
              this.users.splice(0, 0, fetchedUsers);
              // this.$emit("savingFeatureEndEvent");
            }
          });
        }
      } // LIST ends

      if (
        this.currentModuleMembers !== null &&
        this.isUser === false &&
        this.isDialog === true
      ) {
        for (
          var index = 0;
          index < this.currentModuleMembers.data.length;
          index++
        ) {
          const fetchedUsers = {
            profile: "",
            user: "",
            email: "",
            role: "",
            userid: "",
            checked: false
          };
          /**Check if it is Admin before Pushing */
          const isPushAdmin = false;
          const isAnnounceAdmin = false;

          if (this.isPush === true) {
            const Arrays = this.currentModuleMembers.data[
              index
            ].currentAccountRole.moduleRoles.filter(permission => {
              if (permission.accountModuleId === this.currentAccountModuleId) {
                permission.modulePermissions.find(admin => {
                  if (admin === permissions.PUSH) {
                    isPushAdmin = true;
                  }
                });
              }
            });
          } else {
            const Arrays = this.currentModuleMembers.data[
              index
            ].currentAccountRole.moduleRoles.filter(permission => {
              if (permission.accountModuleId === this.currentAccountModuleId) {
                permission.modulePermissions.find(admin => {
                  if (admin === permissions.ANNOUNCEMENT) {
                    isAnnounceAdmin = true;
                  }
                });
              }
            });
          }

          if (
            (isPushAdmin === false && this.isPush === true) ||
            (isAnnounceAdmin === false && this.isPush === false)
          ) {
            /*Profile Initials */

            if (
              !this.currentModuleMembers.data[index].firstName &&
              !this.currentModuleMembers.data[index].lastName
            ) {
              fetchedUsers["profile"] = this.currentModuleMembers.data[
                index
              ].email.charAt(0);
              fetchedUsers["user"] = "NO NAME";
            } else {
              fetchedUsers["profile"] =
                this.currentModuleMembers.data[index].firstName.charAt(0) +
                this.currentModuleMembers.data[index].lastName.charAt(0);

              /*Full Name  */
              fetchedUsers["user"] =
                this.currentModuleMembers.data[index].firstName +
                " " +
                this.currentModuleMembers.data[index].lastName;
            }

            /* Email  */

            fetchedUsers["email"] = this.currentModuleMembers.data[index].email;

            /*Role */

            if (
              this.currentModuleMembers.data[index].currentAccountRole
                .accountRole === roles.ACCOUNT_OWNER
            ) {
              fetchedUsers["role"] = "ACCOUNT OWNER";
            } else {
              fetchedUsers["role"] = "ACCOUNT MEMBER";
            }

            /* User ID */
            fetchedUsers["userid"] = this.currentModuleMembers.data[index]._id;
          } else {
            continue;
          } // finish non-admins
          this.users.splice(0, 0, fetchedUsers);
        }
      } else if (
        this.currentModuleMembers === null &&
        this.isUser === false &&
        this.isList === true
      ) {
        this.$router.push({ name: "communication-module" });
      }
    }
  }
};
</script> 

<style scoped>
.dataTableListContainer {
  /* width: 1200px; */
  background-color: white;
  box-shadow: 0px 0px 82px #00000024;
}
.dataTableDialogContainer {
  /* width: 1000px; */
  background-color: white;
}
.box-shadow-datatable {
  box-shadow: 0px 0px 82px #00000024;
}
.add-feature-admin-label {
  margin-bottom: 10px;
  width: 241px;
  height: 27px;
  text-align: left;
  font-weight: normal;
  font-size: 19px;
  letter-spacing: 1.65px;
  color: #444444;
  text-transform: uppercase;
  opacity: 1;
}
.avatar-style {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
}
.translucentText {
  opacity: 0.4;
}
</style>