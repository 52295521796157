import { getField, updateField } from "vuex-map-fields";
import { longTokenValidation, beezInCodeValidation } from "../../api";

const state = {
  beezinCode: null,
  beezInAccount: null,
  beezInModule: null,
};

const actions = {
  async longTokenValidation({ commit }, { token }) {
    const data = await longTokenValidation(token);
    if (data.userExists == false) {
      commit("updateField", {
        path: "beezinCode",
        value: data.code,
      });
    } else {
      commit("updateField", {
        path: "beezInAccount",
        value: data.account,
      });
    }
    return data;
  },

  async beezInCodeValidation({ commit, dispatch }, { code }) {
    const { user, account, accountModule } = await beezInCodeValidation(code);
    if (account) {
      commit("updateField", {
        path: "beezInAccount",
        value: account,
      });
    }

    if (accountModule) {
      commit("updateField", {
        path: "beezInModule",
        value: accountModule,
      });
    }

    commit("auth/beezInUser", user, { root: true });
    commit("auth/setAccountAfterBeezIn", user, { root: true });
    await dispatch("auth/getUserAccounts", {}, { root: true });
    await dispatch("auth/getCurrentAccount", {}, { root: true });

    return user;
  },
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
