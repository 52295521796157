import { emailVerification, sendVerificationCode } from "../../api";

const actions = {
  async sendVerificationCode({ _ }, email) {
    return await sendVerificationCode(email);
  },

  async verifyCode({ commit }, { email, code }) {
    const user = await emailVerification(code, email);
    commit("auth/verifyCurrentUser", null, { root: true });
    return user;
  }
};

export default {
  namespaced: true,
  actions
};
