<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0" align="center" justify="end">
      <v-col cols="5" lg="5" md="5" class="hidden-sm-and-down">
        <slot name="left">
          <auth-images />
        </slot>
      </v-col>
      <v-col cols="12" sm="12" lg="7" md="7">
        <slot name="right"></slot>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-row align-end justify-center ma-0 pa-0">
      <label
        class="textLight--text font-weight-thin title ma-0 pa-0 pr-4"
      >BeeZ Buro is part of the BeeZ Social ERP, Inc. Family</label>

      <bz-support class="ma-0 pa-0" email="usersupport@beezsocialerp.com"></bz-support>
    </v-row>
  </v-container>
</template>

<script>
import BzSupport from "@shared/components/BzSupport";

import AuthImages from "./AuthImages";
export default {
  name: "AuthContainer",
  components: {
    AuthImages,
    BzSupport
  }
};
</script>
