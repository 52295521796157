<template>
  <v-container class="ma-0 pa-0 container" fluid>
    <v-toolbar
      class="primary pa-2 ma-0 d-flex justify-center align-center background-wave-blue background-container"
      extended
      tile
      extension-height="270"
    >
      <v-row class="d-flex flex-row d-flex justify-center">
        <v-col lg="3" sm="3" cols="12" class="d-flex align-center justify-end">
          <v-img
            max-height="180 "
            max-width="120"
            src="../../assets/b_logo.png"
          ></v-img>
        </v-col>
        <v-divider inset vertical color="white"></v-divider>

        <v-col lg="6" cols="6" sm="6" class="body-1 ">
          <v-row
            class="d-flex flex-column display-1 font-weight-thin textLight--text ml-3"
          >
            <v-col>WELCOME TO BEEZ SOCIAL ERP</v-col>
            <v-col
              >Thank you for choosing us to be your Partner to ENGAGE, EMPOWER &
              ELEVATE your WorkForce.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>
    <!-- <v-row class="d-flex align-center flex-row"> 
        <v-col
          class="display-1 secondaryText--text d-flex align-center justify-center ma-0 pa-0 mt-8 font-weight-thin"
          lg="12"
          cols="12"
        >Welcome To</v-col>
        <v-col
          class="display-2 textOrange--text d-flex align-center justify-center ma-0 pa-0 font-weight-regular"
          lg="12"
          cols="12"
        >{{companyName}}</v-col>
      </v-row> -->
    <!-- <v-row align="center" justify="center" class="mt-12">
            <v-col
              class="ml-8 ma-0 pa-0 display-2 secondaryText--text font-weight-bold"
              lg="1"
              cols="1"
            >{{ item[0] }}</v-col>
            <v-col lg="6" cols="6" class="ma-0 pa-0 ml-2 font-weight-bold textDark--text my-2 mb-2">
              {{ item[1] }}
              <v-col lg="12" cols="12" class="ma-0 pa-0 font-weight-regular mt-8">{{ item[2] }}</v-col>
            </v-col>
          </v-row> -->
    <v-card flat class="d-flex white-wavy-wallpaper justify-center">
      <v-row
        class="d-flex align-center flex-row justify-center ml-5 mt-10"
        v-for="(item, index) in items"
        v-bind:key="index"
      >
        <v-col
          class="display-4 textOrange--text mr-0 pr-0 font-weight-bold"
          lg="4"
          cols="4"
          >{{ item.number }}</v-col
        >
        <v-col class="ma-0 pa-0" lg="6" cols="10">
          <v-row no-gutters class="d-flex flex-column"
            ><v-col lg="12" class="ma-0 pa-0 subtitle-1  font-weight-bold">
              {{ item.title }}</v-col
            ><v-col lg="12" class="ma-0 pa-0 body-2 font-weight-light">{{
              item.description
            }}</v-col></v-row
          ></v-col
        >
      </v-row>
    </v-card>
    <v-col class="d-flex  align-center justify-center mt-12">
      <bz-submit-button :to="{ name: 'admin' }" title="Go To Dashboard" />
    </v-col>
  </v-container>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BzSubmitButton from "@shared/components/BzSubmitButton";
export default {
  name: "Landing",
  data() {
    return {
      items: [
        {
          number: "1. ",
          title: "DASHBOARD",
          description:
            "Your Dashboard contains all of the relevant access you will need to set up your Organization and get started using your Modules.",
        },
        {
          number: "2.",
          title: "ADD/MANAGE ACCOUNTS",
          description:
            "From your Dashboard you can select Accounts in order to add accounts for your Organization.This also gives you the ability to assign roles and tasks to your team and associates.",
        },
        {
          number: "3.",
          title: "MANAGE/ADD USERS",
          description:
            "From your Dashboard you can select Manage Users in order to add, remove and modify people you have selected and invited via email. From this page you can also assign roles and permissions.",
        },
      ],
      companyName: "",
    };
  },
  components: {
    BzSubmitButton,
  },
  created() {
    if (this.beezInAccount) {
      this.companyName = this.beezInAccount.companyName;
    }
  },
  computed: {
    ...mapFields("beezIn", ["beezInAccount"]),
  },
};
</script>
