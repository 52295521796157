export default {
    data() {
        return {
            photoCropper: {
                show: false,
                labels: {
                    submit: "Done",
                    cancel: "Cancel"
                },
                outputMime: "image/png",
                options: {}

            }
        }
    },
    methods: {
        showCropper(show) {
            this.photoCropper.show = show;
        },
        photoCropperToDataURL(file) {
            return file.getCroppedCanvas().toDataURL(this.photoCropper.outputMime);
        },
        async photoCropperGetBlob(file) {
            return new Promise((resolve, reject) => {
                file.getCroppedCanvas().toBlob(blob => {
                    if (!blob){
                        reject("Cannot create blob");
                    } else {
                        resolve(blob);
                    }
                });
            });
        }
    },
    beforeDestroy() {
        this.show = false;
    },
};