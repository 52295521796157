<template>
  <v-container v-if="pageRender" class="ma-0 pa-0 pl-12">
    <!-- <dashboard-header title="Orientation Card" /> -->
    <v-row class="mb-12">
      <v-col lg="6" md="12" sm="12">
        <v-row class="d-flex flex-column mr-12 justify-center">
          <v-col lg="12" md="12" sm="12" class="ma-0">ORIENTATION CARD</v-col>
          <v-col lg="12" md="12" sm="12">
            <v-card outlined class="pa-6 scroll" max-width="500">
              <div class="d-flex flex-row ma-0 pa-0">
                <v-text-field
                  @keypress.enter="addToTask"
                  class="ma-0 pa-0"
                  v-model="task"
                  label="Add New Task"
                  required
                ></v-text-field>
                <!-- @blur="$v.task.$touch" -->
                <!-- :error-messages="taskErrorMessage" -->
                <!-- @keyup.enter="addToTask(i)" -->
                <v-btn icon v-on:click="addToTask()">
                  <v-icon size="x-large" color="black">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div v-if="descriptions.length == 0">
                <v-card
                  class="pa-10 ma-8 d-flex align-center justify-center textDark--text"
                  flat
                >No task to be previewed</v-card>
              </div>
              <div else>
                <bz-tags
                  cardWidth="500"
                  darkColor="primaryLight"
                  lightColor="lightBlue"
                  textColor="primaryLight--text"
                  :listOfTags="descriptions"
                  :editable="true"
                  v-on:removeItem="removeItemAt($event)"
                  class="pa-1"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="6" md="12" sm="12">
        <v-row class="d-flex flex-column mr-12 justify-center">
          <!-- {{ currentUser }} -->
          <!-- {{ selected }} -->
          <v-col lg="12" md="12" sm="12" class>PREVIEW ORIENTATION CARD</v-col>
          <v-col lg="12" md="12" sm="12">
            <v-card outlined class="pa-0 scroll" max-width="500">
              <div class="pa-4 mb-6 primaryDark textLight--text text-center">TASKS</div>
              <div v-if="descriptions.length == 0">
                <v-card
                  class="pa-12 ma-8 d-flex align-center justify-center textDark--text"
                  flat
                >No tasks to be previewed</v-card>
              </div>
              <div v-else>
                <bz-tags
                  v-on:onItemClicked="onItemClicked($event)"
                  cardWidth="500"
                  darkColor="primaryLight"
                  lightColor="lightBlue"
                  textColor="primaryLight--text"
                  :listOfTags="descriptions"
                  class="pa-3"
                  :hasCheckbox="true"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <bz-notification v-model="notification"></bz-notification>
    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p class="body-1 textDark--text mr-12 my-2">Click on Save Button to Save changes</p>
        <bz-submit-button
          title="Save Changes"
          depressed
          icon="mdi-content-save"
          type="Submit"
          width="240"
          :submitTo="submitTo"
          :loading="loading"
          :disabled="disabled"
        >
          <v-divider vertical color="white" class="ml-4 mr-6" />
        </bz-submit-button>
      </div>
    </v-footer>
  </v-container>
  <div v-else class="d-flex justify-center align-center" style="height: 50vh;">
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
// import OrientationCardInput from "../components/OrientationCardInput";
// import OrientationCardPreview from "../components/OrientationCardPreview";
// import DashboardHeader from "@/beez-admin/components/AdminHeader";
import { eventBus } from "../../../../../main";
import { mapActions } from "vuex";
// import { required } from "vuelidate/lib/validators";

import BzSubmitButton from "@shared/components/BzSubmitButton";
import BzTags from "@shared/components/BzTags";
import { mapFields } from "vuex-map-fields";
import BzNotification from "@/shared/components/BzNotification";
import { required } from "vuelidate/lib/validators";

export default {
  name: "OrientationCard",
  data() {
    return {
      checkedBy: null,
      tasks: [],
      selected: [],
      descriptions: [],
      i: 0,
      task: "",
      cardId: null,
      loading: false,
      pageRender: false,
      allTasks: [],
      submitTo: "orientationCard",
      accountId: null,
      disabled: true,
      links: [
        {
          text: "Modules",
          disabled: true,
          href: "/admin/account-modules"
        },
        {
          text: "Employee Onboarding Module",
          disabled: true,
          href: "/admin/modules/on-boarding"
        },
        {
          text: "Orientation Card",
          disabled: false,
          href: "/admin/modules/on-boarding/orientation-card"
        }
      ],
      currentAccountId: "",
      new: false
    };
  },
  validations: {
    descriptions: {
      required
    }
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("orientation", ["orientationCardData"])
    // taskErrorMessage() {
    //   const errors = [];
    //   if (!this.$v.task.$dirty) return [];
    //   !this.$v.task.required && errors.push("Task cannot be empty.");
    //   return errors;
    // },
  },
  components: {
    // DashboardHeader,
    BzTags,
    BzSubmitButton,
    BzNotification
  },
  async created() {
    this.currentAccountId = this.currentUser.accounts[0];
    eventBus.$on(this.submitTo, () => {
      this.orientationCard();
    });

    // let tasks = [];

    await this.getTheTasks();
    this.pageRender = true;

    // this.tasks = tasks[2].tasks;
  },
  async beforeRouteLeave(to, from, next) {
    if (this.checkIfChanges()) {
      let answer = await this.handleDialog();
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
    watch: {
    $v: {
      deep: true,
      handler(value) {
        // console.log(value.$invalid);
        this.disabled = value.$invalid;
      }
    }
    // this.snackbar = true;
  },
  methods: {
    ...mapActions("orientation", {
      createOrientationCard: "createOrientationCard",
      getOrientationTasks: "getOrientationTasks",
      patchOrientationCard: "patchOrientationCard"
    }),

    onItemClicked: function(selected) {
      this.selected = selected;
    },
    async getTheTasks() {
      this.allTasks = [];
      try {
        await this.getOrientationTasks({
          accountId: this.currentAccountId
        });
        if (this.orientationCardData.length > 0) {
          this.allTasks = JSON.parse(JSON.stringify(this.orientationCardData));
        }
        if (this.allTasks.length == 0) {
          this.new = true;
          this.pageRender = true;
          return;
        }
        // let j = 0;
        // this.id = this.allTasks[0].tasks[0].description;
        this.cardId = this.allTasks[0]._id;
        let orientationTask = this.allTasks[0].tasks;
        this.descriptions = [];
        for (var t in orientationTask) {
          let { description } = orientationTask[t];

          this.descriptions.push(description);
          // console.log("oc", t);
        }
      } catch (error) {
        if (error.name === "NotFound") {
          this.tasks = [];
          this.pageRender = true;
        }
      }
    },
    async orientationCard() {
      // if (this.tasks.length == 0) {
      //   this.notify("error", "Some fields are empty");
      //   return;
      // }
      this.loading = true;

      try {
        if (this.new) {
          await this.createOrientationCard({
            accountId: this.currentAccountId,
            tasks: this.tasks
          });
          this.new = false;
          this.notify(
            "success",
            "Successfully added tasks for the Orientation card"
          );
        } else {
          await this.patchOrientationCard({
            cardId: this.cardId,
            accountId: this.currentAccountId,
            tasks: this.tasks
          });
          this.notify(
            "success",
            "Successfully edited tasks for the Orientation card"
          );
        }
      } catch (error) {
        // this.notify("error", "An unexpected error has occured");
      } finally {
        this.loading = false;
        await this.getTheTasks();
      }
      this.tasks=[];
    },
    addToTask() {
      if (this.task == "") {
        return;
      } else {
        this.task = this.task.trim();
      }
      if (this.descriptions.includes(this.task)) {
        this.notify("error", "Cannot add duplicate Task");
        return;
      }
      if (this.new) {
        this.tasks.push({ id: "0", description: this.task });
      } else {
        this.tasks.push({ type: "CREATE", description: this.task });
      }
      this.descriptions.push(this.task);
      this.task = "";
      // console.log(this.tasks, this.descriptions);
    },
    removeItemAt(index) {
      // console.log(this.tasks, this.descriptions);

      let isSent = true;
      for (var x in this.tasks) {
        if (
          ((this.tasks[x].type && this.tasks[x].type == "CREATE") ||
            (this.tasks[x].id && this.tasks[x].id == "0")) &&
          this.tasks[x].description == this.descriptions[index]
        ) {
          this.tasks.splice(x, 1);
          isSent = false;
          // console.log(this.tasks);
          break;
        }
      }
      // console.log(isSent);
      // console.log(this.tasks, this.descriptions);
      if (isSent) {
        let id = "0";
        for (var i in this.allTasks[0].tasks) {
          if (
            this.allTasks[0].tasks[i].description == this.descriptions[index]
          ) {
            id = this.allTasks[0].tasks[i]._id;
          }
        }
        this.tasks.push({
          type: "DELETE",
          id: id,
          description: this.descriptions[index]
        });
      }
      this.descriptions.splice(index, 1);
    },
    async handleDialog() {
      let res = await this.$dialog.confirm({
        title: "Confirmation",
        text: "Are you sure you'd like to leave without saving changes?",
        persistent: true
      });
      return res;
    },
    checkIfChanges() {
      if (this.orientationCardData.length != 0) {
        if (
          this.orientationCardData[0].tasks.length == this.descriptions.length
        ) {
          for (var index in this.orientationCardData[0].tasks) {
            if (
              this.orientationCardData[0].tasks[index].description !==
              this.descriptions[index]
            ) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      } else {
        if (this.descriptions.length !== 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};
</script>