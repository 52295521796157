<template>
  <v-footer height="65" fixed class="d-flex justify-center">
    <div class="d-flex flex-row">
      <p class="body-1 textDark--text mr-12 my-2">
        {{ text }}
      </p>
      <BzButton
        :title="buttonTitle"
        width="240"
        :icon="icon"
        :loading="loading"
        :to="{ name: to }"
        @click="$emit('click')"
        :disabled="disabled"
      ></BzButton>
    </div>
  </v-footer>
</template>

<script>
import BzButton from "@/shared/components/BzSubmitButton";
export default {
  props: ["to", "buttonTitle", "text", "disabled", "loading", "icon"],
  components: {
    BzButton,
  },
};
</script>

<style></style>
