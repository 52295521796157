export default {
  data: () => ({
    notification: {
      show: false,
      message: "",
      color: "",
      timeout: 3000
    }
  }),

  methods: {
    notify(type, message, timeout = 3000) {
      if (!["error", "info", "success"].includes(type)) {
        type = "info";
      }
      if (!message.length) {
        message = "Empty Message";
      }
      this.notification.message = message;
      this.notification.color = type;
      this.timeout = timeout;
      this.notification.show = true;
    }
  }
};
