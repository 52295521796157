import { getField, updateField } from "vuex-map-fields";
import {
  patchWelcomeCard,
  getWelcomeCard,
  filesUpload,
  createWelcomeCard
} from "../../../api";

const state = {
  welcomeCard: [],
  elevationWelcomeIsSeen: false
};

const actions = {
  async patchWelcomeCard(
    { state, rootState },
    { photoId, title, description, links, type }
  ) {
    let accountId = rootState.auth.currentUser.accounts[0];
    let cardId;
    if (type === "welcome-card") {
      cardId = state.welcomeCard[0]._id;
    } else {
      cardId = state.welcomeCard[1]._id;
    }
    const { data } = await patchWelcomeCard(
      cardId,
      photoId,
      title,
      description,
      links,
      accountId
    );

    return data;
  },
  async getWelcomeCard({ rootState, commit }, accountModuleId) {

    let accountId = rootState.auth.currentUser.accounts[0];

    const { data } = await getWelcomeCard(accountId, accountModuleId);

    commit("updateField", {
      path: "welcomeCard",
      value: data
    });

    return data;
  },

  async filesUpload({ _ }, { fileData }) {
    const formData = new FormData();
    formData.append("file", fileData);
    return await filesUpload(formData);
  },
  async createWelcomeCard(
    { rootState },

    { photoId, title, description, links, type }
  ) {
    let accountId = rootState.auth.currentUser.accounts[0];
    let configurationId;
    let accountModuleId;
    if (type === "welcome-card") {
      configurationId = rootState.admin.configurationsIds[0];
      accountModuleId = rootState.admin.onBoarding_accountModulesId;
    } else {
      configurationId = rootState.admin.elevationModule.configurationsIds[0];
      accountModuleId = rootState.admin.elevation_accountModulesId;
    }

    const data = await createWelcomeCard(
      photoId,
      title,
      description,
      links,
      accountId,
      configurationId,
      accountModuleId
    );

    return data;
  },
  elevationWelcomeSeen({ commit }) {
    commit("updateField", {
      path: "elevationWelcomeIsSeen",
      value: true
    });
  }

  //   async patchIntroductionCard({ _ }, { accountId, sectionId, title }) {
  //     return await patchIntroductionCard(accountId, sectionId, title);
  //   }
};

const mutations = {
  updateField
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
