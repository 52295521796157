<template>
  <auth-container>
    <v-col slot="right" justify="center">
      <v-row justify="center">
        <v-img max-width="147" max-height="67" src="../../assets/Buro.svg"></v-img>
      </v-row>
      <v-row justify="center">
        <v-card v-if="!linkSent" outlined class="mt-4 px-6 pb-4" width="340">
          <v-form @submit.prevent="forgotPassword" class="mt-4 pa-2">
            <div class="d-flex flex-column self-align-start">
              <div class="mb-4 textDark--text font-weight-bold">Forgot Password</div>
              <div class="textDark--text form_width">Please enter your email to reset your password</div>
            </div>

            <v-text-field
              v-model="email"
              name="email"
              label="Email Address"
              @blur="$v.email.$touch"
              required
              :error-messages="emailErrorMessages"
            ></v-text-field>

            <transition name="slide-fade">
              <v-alert v-if="hasError" type="error" class="caption pa-1 mt-4">
                {{
                this.errorMessage
                }}
              </v-alert>
            </transition>
            <bz-submit-button
              :loading="loading"
              :disabled="$v.$invalid || this.sendClicked"
              title="Submit"
              svgIcon="submit_icon"
              type="Submit"
              class="full-width"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>

            <div class="mt-4 d-flex flex-column align-start">
              <auth-link-button title="Already a member? Login" :to="{name: 'log-in'}" />
              <auth-link-button title="Not a member? Sign-up" :to="{name: 'sign-up'}" />
            </div>
          </v-form>
        </v-card>
        <v-card
          v-else
          outlined
          class="mt-4 px-4 pb-4 d-flex flex-column align-center justify-center"
          width="350"
        >
          <div class="textDark--text form_width mt-4">
            Please check your email for a link to reset your password. If you
            do not receive an an email please contact our SUPPORT
            TEAM.
          </div>
        </v-card>
      </v-row>
    </v-col>
  </auth-container>
</template>

<script>
import AuthContainer from "../components/AuthContainer";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import AuthLinkButton from "../components/AuthLinkButton";

export default {
  name: "ForgotPasswordCard",
  components: {
    AuthContainer,
    BzSubmitButton,
    AuthLinkButton
  },
  data() {
    return {
      email: "",
      errorMessage: "",
      loading: false,
      sendClicked: false,
      linkSent: false
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    },
    emailErrorMessages() {
      const errors = [];
      if (!this.$v.email.$dirty) return [];
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please enter a valid email address");
      return errors;
    }
  },
  methods: {
    ...mapActions("auth", {
      askPasswordReset: "askPasswordReset"
    }),
    async forgotPassword() {
      this.loading = true;
      try {
        
        await this.askPasswordReset({
          email: this.email
        });
        this.sendClicked = true;
        this.linkSent = true;
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
      } finally {
        this.loading = false;
        this.email = "";
      }

      setTimeout(
        function() {
          this.sendClicked = false;
        }.bind(this),
        30000
      );

      // this.$router.replace({ name: "reset" });
    }
  }
};
</script>

<style scoped>
.form_width {
  width: 260px;
}
</style>
