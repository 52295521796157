<template>
  <v-row class="d-flex align-center justify-center ma-0 pa-0">
    <v-col lg="6" md="6">
      <v-row class="d-flex flex-column align-center justify-center">
        <v-col cols="12" class="mb-10">
          <v-img src="../../assets/beez_slogan.svg"></v-img>
        </v-col>
        <v-col cols="10">
          <v-img class="d-flex align-center justify-center" src="../../assets/two_men.svg"></v-img>
        </v-col>
        <v-col cols="7">
          <v-img src="../../assets/beez_social_erp.svg"></v-img>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AuthImages"
};
</script>