<template>
  <v-btn small class="pa-0 text-capitalize" color="primary" text :to="to" v-on="$listeners">{{title}}</v-btn>
</template>

<script>
export default {
  name: "AuthLinkButton",
  props: {
    title: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: false
    }
  }
};
</script>