import { getField, updateField } from "vuex-map-fields";
import { getModules } from "../../../api";


const state = {
    beezModules: []
};

const mutations = {
    updateField
};

const getters = {
    getField
};

const actions = {

    async getBeeZModules({ rootState }){

        const { accountId } = rootState.auth;
        
        return await getModules(accountId);
    }   

};


export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}