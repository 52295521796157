<template>
  <div class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="pa-0 mt-2 ma-0" cols="11" md="12" lg="12">
        <v-card
          flat
          class="card-shadow ma-0 pa-0 d-flex flex-column"
          max-width="320"
          align="left"
        >
          <v-row
            cols="12"
            lg="12"
            sm="12"
            class="textDark ma-0 pa-4 d-flex flex-row bg-image-container"
          >
            <v-col
              class="ma-0 pa-0 d-flex flex-column align-self-start"
              lg="5"
              md="5"
              cols="5"
              sm="5"
            >
              <v-img class="pa-0 ma-0" src="../../../../../assets/abebech_debebe_square.png"></v-img>
            </v-col>

            <v-col lg="7" cols="7" md="7" sm="7" class="d-flex align-center justify-center">
              <v-row class="ma-0 pa-0 textLight--text">
                <v-col
                  lg="12"
                  cols="12"
                  class="textLight--text font-weight-regular text-uppercase ma-0 pa-0 ml-2"
                >{{ user.firstName }} {{ user.lastName }}</v-col>
                <v-col
                  lg="12"
                  cols="12"
                  class="primaryLight--text font-weight-light text-capitalize ma-0 pa-0 ml-2"
                >{{ jobTitle == '' ? user.position : jobTitle}}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="mx-5 mt-4 mb-0 pa-0">
            <p
              class="mx-0 mt-0 mb-1 pa-0 font-weight-bold text-capitalize"
            >{{ aboutTitle != "" ? aboutTitle : "About"}}</p>
            <p class="ma-0 pa-0 caption">{{ user.about }}</p>
          </div>
          <v-row class="pa-0 d-flex flex-row ma-4">
            <v-col
              class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
              lg="6"
              md="6"
              sm="6"
            >
              <div
                class="d-flex align-self-start pa-0 ma-0 text-capitalize "
              >{{ section0 == '' ? 'Expertise' : section0 }}</div>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="ma-0 pa-0 d-flex align-self-start justify-start"
              >
                <bz-tags
                  class="ma-0 pa-0"
                  darkColor="primaryLight"
                  lightColor="lightBlue"
                  textColor="primaryLight--text"
                  :listOfTags="user.expertise"
                  :small="true"
                />
              </v-col>
            </v-col>

            <v-col
              class="d-flex flex-column justify-center align-center ma-0 py-0 px-1"
              lg="6"
              md="6"
              sm="6"
            >
              <div
                class="d-flex align-self-start pa-0 ma-0 text-capitalize "
              >{{ section1 == '' ? 'Projects' : section1}}</div>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="ma-0 pa-0 d-flex align-self-start justify-start"
              >
                <bz-tags
                  class="ma-0 pa-0"
                  darkColor="textOrange"
                  lightColor="lightOrange"
                  textColor="textOrange--text"
                  :listOfTags="user.projects"
                  :small="true"
                />
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BzTags from "@shared/components/BzTags";
export default {
  name: "IntroductionBeeZCardPreview",
  data() {
    return {
      user: {
        firstName: "Abebech",
        lastName: "Debebe",
        position: "Senior Accountant",
        about:
          "Climate change advocate and author of Africa Green Energy Movement.",
        expertise: ["Green Energy", "Climate Change", "Social Study"],
        projects: ["C40", "Expanse Projects", "World Forum"]
      }
    };
  },
  components: {
    BzTags
  },
  props: {
    section0: {
      type: String,
      required: false,
      default: ""
    },
    section1: {
      type: String,
      required: false,
      default: ""
    },
    jobTitle: {
      type: String,
      required: false
    },
    aboutTitle: {
      type: String,
      required: false,
      default: ""
    }
  }
};
</script>

<style scoped>
.bg-image-container {
  background: #4e4e4e url("../../../../../assets/dark_backdrop_user.svg") 0% 0%
    padding-box;
  border-radius: 3px;
  opacity: 1;
}
.card-shadow {
  box-shadow: 0px 0px 75px #00000024;
}
.text-container {
  word-break: break-all;
  /* display: block;
  /* overflow: auto; */
}
.about-container {
  height: 65px;
}
.job-title-container {
  width: 150px;
  height: 50px;
}
</style>