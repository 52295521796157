<template>
  <auth-container>
    <v-col slot="right" justify="center">
      <v-row justify="center">
        <v-img max-width="147" max-height="67" src="../../assets/Buro.svg"></v-img>
      </v-row>
      <v-row justify="center">
        <v-card outlined class="mt-4 px-6 pb-4" width="340">
          <v-form @submit.prevent="onVerifyCode" class="mt-4 pa-2">
            <div class="d-flex self-justify-start textDark--text font-weight-light">Enter the verification code you have received</div>

            <v-text-field
              v-model="code"
              name="code"
              @blur="$v.code.$touch"
              required
              single-line
              placeholder="6 Digit Code"
              :error-messages="codeErrorMessages"
            ></v-text-field>
            <transition name="slide-fade">
              <v-alert v-if="hasError" type="error" class="caption pa-1 mt-4">
                {{
                this.errorMessage
                }}
              </v-alert>
            </transition>
            <bz-submit-button
              :loading="loading"
              :disabled="codeEntered"
              title="Submit"
              svgIcon="submit_icon"
              class="full-width mt-4"
              type="Submit"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
          </v-form>
          <div class="mt-4 d-flex flex-row align-center">
            <v-btn
              small
              class="pa-0 text-capitalize"
              color="primary"
              text
              @click="onResendVerificationCode()"
              :disabled="resendClicked"
              :loading="resendLoading"
            >Resend verification code?</v-btn>
            <span v-if="resendClicked">
              <p
                class="primary--text body-2 font-weight-regular my-0 pa-0 mr-0 ml-2"
              >{{ countDown }}sec</p>
            </span>
          </div>
        </v-card>
      </v-row>
    </v-col>
  </auth-container>
</template>

<script>
import {
  required,
  email,
  numeric,
  maxLength,
  minLength
} from "vuelidate/lib/validators";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
// import { currentUser } from "../api";

import AuthContainer from "../components/AuthContainer";
import BzSubmitButton from "@shared/components/BzSubmitButton";

export default {
  name: "VerificationCodeCard",
  components: {
    AuthContainer,
    BzSubmitButton
  },
  data() {
    return {
      code: "",
      loading: false,
      errorMessage: "",
      email: "",
      resendClicked: false,
      countDown: 30,
      resendLoading: false
    };
  },
  created() {
    this.email = this.$route.query.email;
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    },
    codeEntered() {
      return this.code.length !== 6;
    },
    codeErrorMessages() {
      const errors = [];
      if (!this.$v.code.$dirty) return [];
      !this.$v.code.required && errors.push("Verification Code is required.");
      !this.$v.code.numeric &&
        errors.push("Verification Code is of Numberic Values");
      !this.$v.code.minLength &&
        errors.push("Verification Code must be exactly 6 characters long");
      return errors;
    }
  },
  validations: {
    code: {
      required,
      numeric,
      minLength: minLength(6),
      maxLength: maxLength(6)
    }
  },
  methods: {
    ...mapActions("verification", {
      sendVerificationCode: "sendVerificationCode",
      verifyCode: "verifyCode"
    }),

    async onVerifyCode() {
      this.loading = true;
      let user;
      try {
        user = await this.verifyCode({
          email: this.email,
          code: this.code
        });
        this.$router.replace({ name: "landing" });
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
      } finally {
        this.loading = false;
      }
    },
    async onResendVerificationCode() {
      this.resendLoading = true;
      try {
        // user
        await this.sendVerificationCode(this.email);
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
        this.resendLoading = false;
        return;
      }
      this.resendLoading = false;
      this.resendClicked = true;
      setTimeout(
        function() {
          this.resendClicked = false;
        }.bind(this),
        30000
      );

      this.countDownTimer();
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.countDown = 30;
        return;
      }
    }
  }
};
</script>
