import { getField, updateField } from "vuex-map-fields";

import {
  fetchTraining,
  createTraining,
  getTraining,
  createCourse,
  patchCourse,
  getCourse,
  getCourses,
  getSpecificCourse,
  addMaterial,
  fetchMaterials,
  deleteMaterial,
  addSubmissionSteps,
  getSubmissionSteps,
  deleteSubmissionSteps,
  addCriteria,
  getCriteria,
  deleteCriteria,
  patchEvaluationDeadline,
  inviteCourseEvaluator,
  deleteEvaluator,
  generateExcel,
} from "../../../api";

const state = {
  availableTrainings: null,
  availableEvaluatorTrainings: null,
  allMaterials: null,
  allSubmissionSteps: null,
  allCriteria: null,
};

const actions = {
  async fetchAvailableTrainings({ commit }) {
    const trainings = await fetchTraining(
      this.state.auth.currentUser.accounts[0]
    );

    commit("updateField", {
      path: "availableTrainings",
      value: trainings.data,
    });
    return trainings;
  },
  async getTraining({ rootState }, id) {
    const { accountId } = rootState.auth;
    const training = await getTraining(accountId, id);
    return training;
  },

  async createNewTraining({ rootState }, { name, description, accountId }) {
    let configurationId = rootState.admin.elevationModule.configurationsIds[1];
    let accountModuleId = rootState.admin.elevation_accountModulesId;
    const training = await createTraining(
      name,
      description,
      configurationId,
      accountId,
      accountModuleId
    );
    return training;
  },
  async createCourse({ _ }, { name, description, trainingId, level }) {
    const course = await createCourse(
      name,
      description,
      trainingId,
      level,
      this.state.auth.currentUser.accounts[0]
    );
    return course;
  },
  async getCourse({ _ }, { courseId, trainingId }) {
    const course = await getCourse(
      courseId,
      trainingId,
      this.state.auth.currentUser.accounts[0]
    );
    return course;
  },
  async getCourses({ rootState }, trainingId) {
    const { accountId } = rootState.auth;
    const course = await getCourses(accountId, trainingId);
    return course;
  },

  async getSpecificCourse({ _ }, { accountid, trainingId, courseId }) {
    const course = await getSpecificCourse(accountid, trainingId, courseId);
    return course;
  },
  async editCourse({ _ }, { patchData }) {
    const course = await patchCourse(patchData);
    return course;
  },

  async addNewMaterial({ _ }, { courseId, title, subtitle, fileId }) {
    const material = await addMaterial(courseId, title, subtitle, fileId);
    return material;
  },

  async fetchAvailableMaterials({ commit }, { courseId, materialsId }) {
    const materials = await fetchMaterials(courseId, materialsId);
    commit("updateField", {
      path: "allMaterials",
      value: materials.data,
    });

    return materials;
  },

  async removeMaterial({ _ }, { patchData }) {
    const deleted = await deleteMaterial(patchData);
    return deleted;
  },

  async addNewSubmission(
    { _ },
    { courseId, title, description, fileType, isOptional }
  ) {
    const submission = await addSubmissionSteps(
      courseId,
      title,
      description,
      fileType,
      isOptional
    );
    return submission;
  },

  async fetchSubmissionSteps({ commit }, { courseId }) {
    const submissions = await getSubmissionSteps(courseId);

    commit("updateField", {
      path: "allSubmissionSteps",
      value: submissions.data,
    });

    return submissions;
  },

  async removeSubmissionSteps({ _ }, { patchData }) {
    const deleted = await deleteSubmissionSteps(patchData);
    return deleted;
  },

  async addNewCriteria({ _ }, { courseId, description, ratingType }) {
    const criteria = await addCriteria(courseId, description, ratingType);
    return criteria;
  },

  async fetchAvailableCriteria({ commit }, { courseId }) {
    const criteria = await getCriteria(courseId);

    commit("updateField", {
      path: "allCriteria",
      value: criteria.data,
    });

    return criteria;
  },

  async removeCriterion({ _ }, { patchData }) {
    const deleted = await deleteCriteria(patchData);
    return deleted;
  },

  async evaluationDeadline({ _ }, { courseId, deadline }) {
    const course = await patchEvaluationDeadline(courseId, deadline);
    return course;
  },

  async inviteNewEvaluator(
    { _ },
    { email, accountId, courseId, assignedRole }
  ) {
    const data = await inviteCourseEvaluator(
      email,
      accountId,
      courseId,
      assignedRole
    );
    return data;
  },

  async removeEvaluator({ _ }, { patchData }) {
    const deleted = await deleteEvaluator(patchData);
    return deleted;
  },

  async generateExcel({ _ }, { courseId }) {
    const { url } = await generateExcel(courseId);
    return url;
  },
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
