/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'elevation-rise': {
    width: 30.4,
    height: 24.067,
    viewBox: '0 0 30.4 24.067',
    data: '<path pid="0" d="M0 24.067v-6.266l13.14-7.583 6.367 3.716L30.4 7.6v16.467H0M30.4 3.699l-10.893 6.3-6.367-3.666L0 13.933v-3.732l13.14-7.583 6.367 3.716L30.4 0z" _fill="#fff"/>'
  }
})
