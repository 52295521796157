<template>
  <v-card
    max-width="150"
    :href="`mailto:${email}`"
    color="textOrange"
    class="d-flex pa-2 align-center justify-center"
  >
    <label class="textLight--text body-2">NEED HELP?</label>
    <v-divider vertical class="textLight mx-2"></v-divider>
    <v-icon color="white">mdi-email</v-icon>
  </v-card>
</template>

<script>
export default {
  name: "BzSupport",
  props: ["email"]
};
</script>

<style></style>
