/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'submit_icon': {
    width: 17.282,
    height: 12.988,
    viewBox: '0 0 17.282 12.988',
    data: '<path pid="0" data-name="Path 234" d="M15.142 0v5.5H4.066l3.852-3.852-1.5-1.5L0 6.568l6.419 6.419 1.5-1.5-3.853-3.849h13.216V0z" _fill="#fff"/>'
  }
})
