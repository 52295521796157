<template>
  <v-container fluid fill-height class="d-flex justify-center">
    <div class="background-beez-in"></div>
    <v-col cols="3" class="hidden-sm-and-down">
      <v-col class="d-flex align-center justify-center ma-0 pa-0" cols="12">
        <v-row class="d-flex flex-column align-center justify-center">
          <v-col cols="10" class="mb-10">
            <v-img src="../../assets/beez_slogan.svg"></v-img>
          </v-col>
          <v-col cols="8">
            <v-img class="d-flex align-center justify-center" src="../../assets/two_men.svg"></v-img>
          </v-col>
          <v-col cols="5">
            <v-img src="../../assets/beez_social_erp.svg"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <v-col cols="6" lg="2" md="2" sm="6">
      <label class="title font-weight-regular textLight--text mb-0">BeeZ-In Code</label>
      <br>
      <label
        class="textLight--text body-1 font-weight-light"
      >Your BeeZ-In Code is a unique code that is designed to customize your beez buro Experience.</label>
    </v-col>
    <v-col cols="12" sm="12" lg="5" md="5">
      <v-row justify="center">
        <v-img max-width="147" max-height="67" src="../../assets/Buro.svg"></v-img>
      </v-row>
      <v-row justify="center">
        <v-card v-if="!beezinSuccess" outlined class="mt-4 px-6 pb-4" width="340">
          <v-form @submit.prevent="validateCode" class="mt-4">
            <div
              class="textDark--text d-flex self-justify-start"
            >Please enter the BeeZ-In Code you have received</div>

            <v-text-field
              v-model="code"
              name="code"
              label="8-digit BeeZ-In Code"
              @blur="$v.code.$touch"
              required
              :error-messages="codeErrorMessages"
            ></v-text-field>

            <transition name="slide-fade">
              <v-alert
                v-if="hasError"
                type="error"
                class="caption pa-1 mt-4"
              >{{ this.errorMessage }}</v-alert>
            </transition>

            <bz-submit-button
              :loading="loading"
              :disabled="this.$v.$invalid"
              title="Submit"
              svgIcon="submit_icon"
              type="Submit"
              class="full-width mt-4"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
          </v-form>
        </v-card>
        <v-card v-else outlined class="mt-2 px-6 pb-4" width="340px">
          <div class="mx-4 mt-4 primary--text headline font-weight-regular">Congratulations</div>
          <div class="textDark--text font-weight-light subtitle-2 mx-4 mb-4">BeeZ-In Code confirmed</div>
          <bz-submit-button
            class="full-width mb-2"
            title="Next"
            svgIcon="submit_icon"
            :to="{name: nextRoute}"
          >
            <v-divider vertical color="white" class="ml-2" />
          </bz-submit-button>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>


<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
// import { isUndefined, isNull } from "util";
import AuthImages from "../components/AuthImages";
// import BzButton from "@shared/components/BzButton";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import AuthContainer from "../components/AuthContainer";
import { roles } from "@/shared/constants/roles";

export default {
  name: "BeezInCodeCard",
  data() {
    return {
      code: "",
      loading: false,
      errorMessage: "",
      beezinSuccess: false,
      beezIntoModule: false,
      nextRoute: ""
    };
  },
  components: {
    AuthImages,
    // BzButton,
    BzSubmitButton,
    AuthContainer
  },
  validations: {
    code: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(8)
    }
  },
  created() {
    if (this.$route.query.beezIntoModule) {
      this.beezIntoModule = true;
    }
  },

  computed: {
    ...mapFields("auth", ["currentUser", "accountRoles"]),
    codeErrorMessages() {
      const errors = [];
      if (!this.$v.code.$dirty) return [];
      !this.$v.code.required && errors.push("BeeZ In Code is required.");
      (!this.$v.code.minLength || !this.$v.code.maxLength) &&
        errors.push("BeeZ In Code must be exactly 8 characters long");
      return errors;
    },
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    }
  },
  watch: {
    accountRoles: {
      handler(value) {
        this.checkRole();
        this.beezinSuccess = true;
      }
    }
  },
  methods: {
    ...mapActions("beezIn", {
      beezInCodeValidation: "beezInCodeValidation"
    }),
    checkRole() {
      if (this.accountRoles.includes(roles.ACCOUNT_OWNER)) {
        this.nextRoute = "landing";
      } else if (this.accountRoles.includes(roles.ONBOARDING_MODULE_MEMBER)) {
        if (this.beezIntoModule) {
          this.nextRoute = "elevation-landing-page";
        } else {
          this.nextRoute = "user-home";
        }
      } else if (this.accountRoles.includes(roles.COURSE_EVALUATOR)) {
        this.nextRoute = "evaluator-home";
      }
    },
    async validateCode() {
      // perform form validation
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      // present loading indicator on button
      this.loading = true;
      try {
        await this.beezInCodeValidation({
          code: this.code
        });
        // this.$router.replace({ name: "account-dashboard" });
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
      } finally {
        this.loading = false;
        this.code = "";
      }
    }
  }
};
</script>

<style scoped>
.background-beez-in {
  background: url("../../assets/beez_in_bg.svg") no-repeat center center;
  height: 95%;
  width: 75%;
  background-size: contain;
  position: absolute;
  opacity: 0.2;
}
</style>
