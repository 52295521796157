<template>
  <v-container v-if="renderCard" class="ma-0 pa-0">
    <v-col cols="12" class="mb-12 pa-0 ml-12">
      <IntroductionBeeZCardTemplate
        :jobTitle="card.jobTitle"
        class="pb-1"
        :aboutTitle="card.aboutTitle"
        :sections="card.sections"
        v-on:changeField="updateField($event)"
        v-on:formInvalid="setDisabled($event)"
      />
    </v-col>
    <bz-notification v-model="notification"></bz-notification>
    <v-footer height="65" absolute class="d-flex justify-center ma-0">
      <div class="d-flex flex-row">
        <p class="body-1 mr-12 my-2">Click on Save Button to Save changes</p>
        <bz-submit-button
          title="Save Changes"
          depressed
          icon="mdi-content-save"
          type="Submit"
          :submitTo="submitTo"
          width="240"
          :loading="loading"
          :disabled="isDisabled"
        >
          <v-divider vertical color="white" class="ml-4 mr-6" />
        </bz-submit-button>
      </div>
    </v-footer>
  </v-container>
  <div v-else class="d-flex justify-center align-center" style="height: 50vh;">
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import IntroductionBeeZCardTemplate from "./IntroductionBeeZCardTemplate";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import { eventBus } from "../../../../../main";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import BzNotification from "@/shared/components/BzNotification";

export default {
  name: "IntroductionBeeZCardView",
  data() {
    return {
      card: {
        id: null,
        jobTitle: "",
        aboutTitle: "",
        sections: ["", ""]
      },
      oldCard: {
        id: null,
        jobTitle: "",
        aboutTitle: "",
        sections: ["", ""]
      },
      new: true,
      accountId: null,
      submitTo: "IntroductionCard",
      loading: false,
      renderCard: false,
      isDisabled: true
    };
  },
  methods: {
    ...mapActions("introduction", {
      createIntroductionCard: "createIntroductionCard",
      getIntroductionCard: "getIntroductionCard",
      patchIntroductionCard: "patchIntroductionCard",
      patchIntroductionCardTitles: "patchIntroductionCardTitles"
    }),
    setDisabled(value) {
      this.isDisabled = value;
      // console.log(value);
    },
    async onSubmit() {
      // console.log(this.card.jobTitle, this.card.aboutTitle, this.card.sections);

      this.loading = true;
      if (this.new) {
        try {
          await this.createIntroductionCard({
            jobTitle: this.card.jobTitle,
            aboutTitle: this.card.aboutTitle,
            sections: this.card.sections,
            accountId: this.accountId
          });
          // await this.getIntroductionCard({ accountId: this.accountId });
          this.new = false;
          this.notify(
            "success",
            "Successfully created the Introduction BeeZCard"
          );
        } catch (error) {
          // this.notify("error", "An unexpected error has occured");
        } finally {
          this.loading = false;
          await this.getTheCard();
        }
      } else {
        try {
          for (var index in this.card.sections) {
            await this.patchIntroductionCard({
              accountId: this.accountId,
              title: this.card.sections[index].title,
              sectionId: this.card.sections[index].id
            });
          }
          await this.patchIntroductionCardTitles({
            jobTitle: this.card.jobTitle,
            aboutTitle: this.card.aboutTitle,
            accountId: this.accountId,
            cardId: this.card.id
          });
          this.notify(
            "success",
            "Successfully edited the Introduction BeeZCard"
          );
        } catch (error) {
          console.log(error);
          // this.notify("error", "An unexpected error has occured");
        } finally {
          this.loading = false;
          await this.getTheCard();
        }
      }
    },
    updateField(fieldObject) {
      fieldObject.jobTitle !== undefined
        ? (this.card.jobTitle = fieldObject.jobTitle)
        : false;
      fieldObject.aboutTitle !== undefined
        ? (this.card.aboutTitle = fieldObject.aboutTitle)
        : false;
      if (this.new) {
        fieldObject.section0 !== undefined
          ? (this.card.sections[0] = fieldObject.section0)
          : false;
        fieldObject.section1 !== undefined
          ? (this.card.sections[1] = fieldObject.section1)
          : false;
      } else {
        fieldObject.section0 !== undefined
          ? (this.card.sections[0].title = fieldObject.section0)
          : false;
        fieldObject.section1 !== undefined
          ? (this.card.sections[1].title = fieldObject.section1)
          : false;
      }
    },
    async handleDialog() {
      let res = await this.$dialog.confirm({
        title: "Confirmation",
        text: "Are you sure you'd like to leave without saving changes?",
        persistent: true
      });
      return res;
    },
    checkIfChanges() {
      if (JSON.stringify(this.oldCard) !== JSON.stringify(this.card)) {
        return true;
      } else {
        return false;
      }
    },
    async getTheCard() {
      this.accountId = this.currentUser.accounts[0];
      const card = await this.getIntroductionCard({
        accountId: this.accountId
      });
      if (card.length > 0) {
        this.new = false;
        this.card.id = card[0]._id;
        this.card.jobTitle = card[0].jobTitle;
        this.card.aboutTitle = card[0].aboutTitle;
        let sections = [];
        let sectionObj = {};
        for (var index in card[0].sections) {
          sectionObj["id"] = card[0].sections[index]._id;
          sectionObj["title"] = card[0].sections[index].title;
          sections.push(sectionObj);
          sectionObj = {};
        }
        this.card.sections = sections;
        this.oldCard = JSON.parse(JSON.stringify(this.card));
      }
    }
  },
  components: {
    IntroductionBeeZCardTemplate,
    BzSubmitButton,
    BzNotification
  },
  async beforeRouteLeave(to, from, next) {
    if (this.checkIfChanges()) {
      let answer = await this.handleDialog();
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  async created() {
    eventBus.$on(this.submitTo, () => {
      this.onSubmit();
    });
    // if (this.currentUser == null) {
    //   await this.$parent.reAuth();
    // }
    await this.getTheCard();
    this.renderCard = true;
  },
  computed: {
    ...mapFields("auth", ["currentUser"])
  }
};
</script>
<style scoped></style>
