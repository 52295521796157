<template>
  <div>
    <v-card max-width="750">
      <v-card-title class="justify-center primaryLight">
        <v-icon class="display-4 white--text">mdi-account-box</v-icon>
        <h1 class="white--text display-1">{{ landingPage.title }}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <h2 class="subtitle-1 textOrange--text mb-4 ml-4">
              {{ landingPage.stepsTitle }}
            </h2>
            <h3 class="subtitle-1 ml-5 font-weight-bold">STEPS</h3>
            <v-stepper vertical class="elevation-0">
              <div class="" v-for="(step, i) in landingPage.steps" :key="i">
                <v-stepper-step
                  complete
                  complete-icon=""
                  class="my-0 py-0"
                  :step="i"
                >
                  <h2 class="body-2">{{ step }}</h2>
                </v-stepper-step>

                <v-stepper-content
                  class="my-0 py-2 ml-7"
                  v-if="i < landingPage.steps.length - 1"
                  :step="i"
                >
                </v-stepper-content>
              </div>
            </v-stepper>
          </v-col>
          <v-col cols="12" md="6" class="px-10">
            <h2 class="subtitle-1 textOrange--text mb-4">
              {{ landingPage.descriptionTitle }}
            </h2>
            <p>
              {{ landingPage.description }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <bz-footer buttonTitle="Next" :to="landingPage.next"></bz-footer>
  </div>
</template>

<script>
export default {
  created() {
    this.landingPage = this.pageContent[this.$route.meta.name];
  },
  data: () => ({
    landingPage: {},
    pageContent: {
      onboarding: {
        title: "Onboarding Module",
        stepsTitle: "Build your BeeZCard",
        steps: [
          "Upload BeeZCard Photo",
          "Add Job Title / Position",
          "Write Something About You",
          "Add 3 Expertise BeeZTags",
          "Add 3 Project BeeZTags",
        ],
        descriptionTitle: "Module Information:",
        description:
          "Automates the on-boarding process of users to an organizational entity by using an end to end workflow to speed up the integration and orientation process of a new team member into the Organization.",
        next: "welcome",
      },
      orientation: {
        title: "Orientation Card",
        stepsTitle: "Complete Orientation Card",
        steps: [
          "Check Orientation Card",
          "Complete Tasks",
          "BeeZ In to relevant Modules",
        ],
        descriptionTitle: "Card Information:",
        description:
          "An Organizational specific social media communication tool that fosters engagement between all the stakeholders inside and out side the Organization.",
        next: "orientation",
      },
      elevation: {
        title: "Elevation Module",
        stepsTitle: "Access Training/Courses",
        steps: [
          "BeeZ In to the Elevation Module",
          "Select a Training Course",
          "Review Syllabus",
          "Upload Documents",
          "Submit and Start",
        ],
        descriptionTitle: "Module Information:",
        description:
          "Elevation Module creates and streamline training opportunities, surveys, feedback loops and educational opportunities the encourage growth and elevate your workforce to increase productivity",
        next: "elevation-welcome",
      },
    },
  }),
};
</script>

<style scoped>
h1 {
  max-width: 180px;
  word-break: break-word;
}
.v-stepper__content {
  border-left: 1px solid;
}
div >>> span.primary {
  min-width: 10px;
  width: 10px;
  height: 10px;
}
</style>
