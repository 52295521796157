import { getField, updateField } from "vuex-map-fields";
import { getAllAccountUsers, inviteAccountOwner } from "../../api";

const state = {
  allAccountUsers: null,
  inviteAccountOwnerData: null
};

const actions = {
  // fetch all Account Users
  async fetchAllAccountUsers({ commit }, { level, accountid }) {
    const accountUsers = await getAllAccountUsers(level, accountid);
    commit("updateField", {
      path: "allAccountUsers",
      value: accountUsers
    });
    return accountUsers;
  },

  // invite Account Owner
  async inviteNewAccountOwner({ commit }, { email, accountId, assignedRole }) {
    const data = await inviteAccountOwner(email, accountId, assignedRole);

    commit("updateField", {
      path: "inviteAccountOwnerData",
      value: data
    });
    // this.accountData.pendingAccountOwners.length
    return data;
  }
};

const mutations = {
  updateField
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
