const roles = {
  ACCOUNT_OWNER: "ACCOUNT_OWNER",
  ONBOARDING_MODULE_MEMBER: "ONBOARDING_MODULE_MEMBER",
  SUPER_ADMIN: "SUPER_ADMIN",
  ANNOUNCEMENT_ADMINISTRATOR: "ANNOUNCEMENT_ADMINISTRATOR",
  TRAINING_MEMBER: "TRAINING_MEMBER",
  COURSE_EVALUATOR: "COURSE_EVALUATOR",
  PUSH_ADMINISTRATOR: "PUSH_ADMINISTRATOR",
  COMMUNICATION_MODULE_MEMBER: "COMMUNICATION_MODULE_MEMBER",
};

Object.freeze(roles);

export { roles };
