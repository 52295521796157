import { getField, updateField } from "vuex-map-fields";

import {
  editAccount,
  getAllAccounts,
  filesUpload,
  getAccountModules,
  fetchFilesUploaded,
  getOnBoardingModules,
  getElevationModules,
  accountLogoEdit,
  getUserAccountModules,
  updateAccount
} from "../../../api";

const state = {
  mainHeaderTitle: "Title",
  onBoardingModules: [],
  breadcrumbs: [],
  organizationLogo: null,
  allAccounts: null,
  accountModules: [],
  configurationsIds: [],
  onBoarding_accountModulesId: '',
  elevation_accountModulesId: '',
  onBoardPreference: [],
  elevationModule: [],
  elevationModuleData: []
};

const actions = {

  async updateAccount({ commit, rootState }, payload){
    const { accountId } = rootState.auth; 
    const data = await updateAccount(accountId, payload);

  },

  async editAccount(
    { commit },
    { index, accountId, accountName, companyName, domain }
  ) {
    let account = await editAccount(
      accountId,
      accountName,
      companyName,
      domain
    );
    commit("updateAccount", { index, account });
    return account;
  },

  async getAllAccounts({ commit }) {
    const { accounts } = await getAllAccounts();
    commit("updateField", {
      path: "allAccounts",
      value: accounts
    });
    return accounts;
  },

  async uploadFile({ _ }, { fileData }) {
    const formData = new FormData();
    formData.append("file", fileData);
    return await filesUpload(formData);
  },

  async uploadOrganizationLogo({ commit }, { fileData }) {
    const formData = new FormData();
    formData.append("file", fileData);
    const fileUploaded = await filesUpload(formData);
    commit("updateField", {
      path: "organizationLogo",
      value: fileUploaded[0]
    });
    return await filesUpload(formData);
  },

  async accountLogoEdit({ _ }, { accountId, logoId }) {
    const organizationLogo = await accountLogoEdit(accountId, logoId);
    return organizationLogo;
  },
  async fetchFilesUploaded({ _ }, { logoId }) {
    const organizationLogo = await fetchFilesUploaded(logoId);
    // commit("updateField", {
    //   path: "organizationLogo",
    //   value: organizationLogo
    // });
    return organizationLogo;
  },
  async getAccountModules({ commit }, accountId) {
    const data = await getAccountModules(accountId);
    // console.log("Account Modules", data);
    commit("updateField", {
      path: "accountModules",
      value: data
    });
    commit("updateField", {
      path: "configurationsIds",
      value: data[0].configurationsIds
    });
    commit("updateField", {
      path: "onBoarding_accountModulesId",
      value: data[0]._id
    });
    commit("updateField", {
      path: "elevation_accountModulesId",
      value: data[1]._id
    });
    commit("updateField", {
      path: "elevationModule",
      value: data[1]
    });
    commit("updateField", {
      path: "onBoardPreference",
      value: data[0]
    });
    return data;
  },
  async getOnBoardingModules({ commit, state }, accountId) {
    console.log(state.elevationModule, "onBoardbord");
    const data = await getOnBoardingModules(state.configurationsIds, accountId);
    commit("updateField", {
      path: "onBoardingModules",
      value: data
    });

    return data;
  },

  async getElevationModules({ commit, state }, accountId) {
    const data = await getElevationModules(
      state.elevationModule.configurationsIds,
      accountId
    );

    commit("updateField", {
      path: "elevationModuleData",
      value: data
    });
    return data;
  },

  async getUserAccountModules({ commit }) {
    const { data } = await getUserAccountModules();
    commit("updateField", {
      path: "configurationsIds",
      value: data[0].configurationsIds
    });
    commit("updateField", {
      path: "elevationModule",
      value: data[2]
    });
    commit("updateField", {
      path: "onBoardPreference",
      value: data[0]
    });

    return data;
  }
};

const mutations = {
  updateField,
  updateAccount(state, { index, account }) {
    if (state.organizationLogo !== null) {
      account["logo"] = state.organizationLogo;
    } else {
      account["logo"] = state.allAccounts[index].logo;
    }
    state.allAccounts.splice(index, 1, account);
  }
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
