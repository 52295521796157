import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../store/index.js";
import { roles } from "@/shared/constants/roles";

import Auth from "@/auth/pages/Auth";

//Components

//Auth Childs
import BeezInCodeCard from "@/auth/pages/BeezInCodeCard";
import ForgotPasswordCard from "@/auth/pages/ForgotPasswordCard.vue";
import VerificationCodeCard from "@/auth/pages/VerificationCodeCard.vue";
import ResetPasswordCard from "@/auth/pages/ResetPasswordCard.vue";

//Dashboard Childs
// import AccountDashboard from "@/beez-admin/pages/AccountDashboard";
import AccountSettings from "@/beez-admin/pages/AccountSettings";
import Announcement from "@/beez-admin/modules/communication/pages/Announcement";
import PushNotification from "@/beez-admin/modules/communication/pages/PushNotification";

// Modules
import OrientationCard from "@/beez-admin/modules/on-boarding/pages/OrientationCard/OrientationCard.vue";
import IntroductionBeeZCardView from "@/beez-admin/modules/on-boarding/pages/IntroductionBeeZCard/IntroductionBeeZCardView.vue";
import Landing from "@/beez-admin/pages/Landing";

import LandingPage from "@/shared/components/Landing.vue";
const AccountDashboard = () => import("@/beez-admin/pages/AccountDashboard");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "admin",
    },
  },
  {
    path: "/admin",
    component: () => import("@/beez-admin/pages/Main"),
    name: "admin",
    redirect: {
      name: "account-dashboard",
    },
    children: [
      {
        path: "",
        name: "account-dashboard",
        component: AccountDashboard,
        meta: {
          breadcrumbs: [
            {
              text: "Dashboard",
            },
          ],
        },
      },
      {
        path: "account-usersroles",
        name: "account-usersroles",
        component: () =>
          import("@/beez-admin/modules/communication/pages/UsersRoles"),
        meta: {
          breadcrumbs: [
            {
              text: "Users and Roles",
            },
          ],
        },
      },

      {
        path: "account-settings",
        name: "account-settings",
        component: AccountSettings,
        meta: {
          breadcrumbs: [
            {
              text: "Account Settings",
            },
          ],
        },
      },
      {
        path: "modules",
        name: "modules",
        component: () => import("../beez-admin/pages/AccountModules"),
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
            },
          ],
        },
      },

      {
        path: "modules/on-boarding",
        name: "onboarding-module",
        component: () =>
          import(
            "../beez-admin/modules/on-boarding/pages/EmployeeOnBoardingModule"
          ),

        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Employee Onboarding Module",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "modules/elevation",
        name: "elevation-module",
        component: () =>
          import("../beez-admin/modules/elevation/pages/ElevationModule"),
        meta: {
          breadcrumbs: {
            skip: false,
          },
        },
        // meta: {
        //   breadcrumbs: [
        //     {
        //       text: "Modules",
        //       disabled: false,
        //       to: {
        //         name: "modules"
        //       }
        //     },
        //     {
        //       text: "Elevation Module",
        //       disabled: true
        //     }
        //   ]
        // }
      },
      {
        path: "modules/elevation/elevation-welcome-card/",
        name: "elevation-welcome-card",
        component: () =>
          import("../beez-admin/modules/on-boarding/pages/WelcomeCardView.vue"),
        props: true,

        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Elevation Module",
              disabled: false,
              to: {
                name: "elevation-module",
              },
            },
            {
              text: "Welcome Card",
              disabled: true,
            },
          ],
        },
      },

      {
        path: "modules/elevation/training",
        name: "elevation-training-card",
        component: () =>
          import("../beez-admin/modules/elevation/pages/Training/Trainings"),
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Elevation Module",
              disabled: false,
              to: {
                name: "elevation-module",
              },
            },
            {
              text: "Training",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "modules/elevation/training/course-home",
        name: "course-home",
        component: () =>
          import("../beez-admin/modules/elevation/pages/Course/CourseHome"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "modules/elevation/training/course-home/add",
        name: "add-course",
        component: () =>
          import("../beez-admin/modules/elevation/pages/Course/AddCourse"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      // {
      //   path: "modules/elevation/training/course-home/courses",
      //   name: "courses",
      //   component: () =>
      //     import(
      //       "../beez-admin/modules/elevation/pages/Course/Courses"
      //     ),
      //   meta: {
      //     breadcrumbs: {
      //       skip: true
      //     }
      //   }
      // },
      {
        path: "modules/communication",
        name: "communication-module",
        component: () =>
          import(
            "../beez-admin/modules/communication/pages/CommunicationModule"
          ),
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Communication Module",
              disabled: false,
            },
          ],
        },
      },
      {
        path: "modules/communication/announcement",
        name: "announcement",
        component: Announcement,
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Communication Module",
              disabled: false,
              to: {
                name: "communication-module",
              },
            },
            {
              text: "Announcement",
              disabled: false,
            },
          ],
        },
      },
      {
        path: "modules/communication/push-notification",
        name: "push-notification",
        component: PushNotification,
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Communication Module",
              disabled: false,
              to: {
                name: "communication-module",
              },
            },
            {
              text: "Push Notification",
              disabled: false,
            },
          ],
        },
      },
      {
        path: "modules/on-boarding/orientation-card",
        name: "orientation-card",
        component: OrientationCard,
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Employee On-Boarding",
              disabled: false,
              to: {
                name: "onboarding-module",
              },
            },
            {
              text: "Orientation Card",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "modules/on-boarding/introduction-beezcard",
        name: "introduction-beezcard",
        component: IntroductionBeeZCardView,
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Employee On-Boarding ",
              disabled: false,
              to: {
                name: "onboarding-module",
              },
            },
            {
              text: "Introduction BeezCard",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "modules/on-boarding/welcome-card",
        name: "welcome-card",
        component: () =>
          import("@/beez-admin/modules/on-boarding/pages/WelcomeCardView"),
        meta: {
          breadcrumbs: [
            {
              text: "Modules",
              disabled: false,
              to: {
                name: "modules",
              },
            },
            {
              text: "Employee On-Boarding",
              disabled: false,
              to: {
                name: "onboarding-module",
              },
            },
            {
              text: "Welcome Card",
              disabled: true,
            },
          ],
        },
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    redirect: {
      name: "log-in",
    },
    children: [
      {
        path: "",
        name: "log-in",
        component: () => import("@/auth/pages/LoginPage"),
      },
      {
        path: "sign-up",
        name: "sign-up",
        component: () => import("@/auth/pages/SignUpPage"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: ForgotPasswordCard,
      },
      {
        path: "reset-password/:id",
        name: "reset-password",
        component: ResetPasswordCard,
      },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: "/verification",
    name: "verification",
    component: () => import("@/auth/pages/VerificationRoute"),
    children: [
      {
        path: "beez-in",
        name: "beez-in",
        component: BeezInCodeCard,
      },
      {
        path: "verify",
        name: "verify",
        component: VerificationCodeCard,
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/welcome",
    name: "landing",
    component: Landing,
    meta: { requiresAuth: true },
  },
  {
    path: "/accept/:id",
    name: "loading",
    component: () => import("@/beez-admin/pages/LoadingScreen"),
    meta: { requiresAuth: false },
  },
  {
    path: "/onboarding",
    name: "user-home",
    component: () => import("@/beez-modules/beez-user/pages/UserHome"),
    meta: { requiresAuth: true },
    redirect: {
      name: "user-profile",
    },
    children: [
      {
        path: "home",
        name: "onboarding-landing-page",
        component: LandingPage,
        meta: {
          name: "onboarding",
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "dashboard",
        name: "user-profile",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/dashboard/Dashboard.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              text: "Your Profile",
            },
          ],
        },
      },
      {
        path: "introduction",
        name: "introduction",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/introduction/IntroductionCard"
          ),
        meta: {
          breadcrumbs: {
            skip: false,
          },
        },
      },
      {
        path: "introduction/build",
        name: "introduction-build",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/introduction/IntroductionCardBuild"
          ),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "orientation/home",
        name: "orientation-landing-page",
        component: LandingPage,
        meta: {
          name: "orientation",
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "orientation",
        name: "orientation",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/orientation/OrientationCard"
          ),
        meta: {
          breadcrumbs: {
            skip: false,
          },
        },
      },
      {
        path: "welcome",
        name: "welcome",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/welcome/WelcomeCard"
          ),
        meta: {
          breadcrumbs: {
            skip: false,
          },
        },
      },
      {
        path: "elevation-module/home",
        name: "elevation-landing-page",
        component: LandingPage,
        meta: {
          name: "elevation",
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "elevation-module/welcome",
        name: "elevation-welcome",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/on-boarding/welcome/WelcomeCard"
          ),
        meta: {
          breadcrumbs: {
            skip: false,
          },
        },
      },
      {
        path: "elevation-module/training",
        name: "user-training",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/elevation/training/Training"
          ),
        meta: {
          breadcrumbs: [
            // {
            //   text: "Elevation Module",
            //   disabled: true,
            //   to: {
            //     name: "modules"
            //   }
            // },
            {
              text: "Trainings",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "elevation-module/training/courses/course-info/:id",
        name: "user-course-info",
        props: true,
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/elevation/courses/CourseInfo"
          ),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "elevation-module/training/courses/:trainingId",
        name: "user-courses",
        props: true,
        component: () =>
          import("@/beez-modules/beez-user/modules/elevation/courses/Courses"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path:
          "elevation-module/training/courses/submission/:trainingId/:courseId",
        name: "submission",
        component: () =>
          import(
            "@/beez-modules/beez-user/modules/elevation/submission/Submission"
          ),
        props: true,
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
    ],
  },
  {
    path: "/modules/elevation/evaluator",
    name: "evaluator-home",
    component: () =>
      import("@/beez-modules/beez-evaluator/pages/EvaluatorMain"),
    meta: { requiresAuth: true },
    redirect: {
      name: "evaluator-training",
    },
    children: [
      {
        path: "training/course-home",
        name: "evaluator-course-home",
        component: () =>
          import("../beez-admin/modules/elevation/pages/Course/CourseHome"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "training",
        name: "evaluator-training",
        component: () =>
          import("../beez-admin/modules/elevation/pages/Training/Trainings"),
        meta: {
          breadcrumbs: [
            {
              text: "Elevation Module",
              disabled: true,
            },
            {
              text: "Training",
              disabled: true,
            },
          ],
        },
      },
      {
        path: "trainees",
        name: "evaluator-trainees-list",
        component: () =>
          import("@/beez-modules/beez-evaluator/pages/TraineesList"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "trainees/trainee",
        name: "evaluator-trainee-info",
        component: () =>
          import("@/beez-modules/beez-evaluator/pages/TraineeInfo"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "trainees/trainee/submission",
        name: "SubmissionList",
        component: () =>
          import("@/beez-modules/beez-evaluator/pages/SubmissionList"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
      {
        path: "trainees/trainee/evaluate",
        name: "evaluate-trainee",
        component: () =>
          import("@/beez-modules/beez-evaluator/pages/Evaluation"),
        meta: {
          breadcrumbs: {
            skip: true,
          },
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

/**
 * Implementation of the global navigation guard
 */

router.beforeEach(async (to, from, next) => {
  let isUserAuthenticated = false;
  let isVerified = false;
  let isBeezIn = false;
  let email = "";
  let accountRoles = [];
  // TODO: check if the user is an admin or not
  // TODO: check if the user has access to at least one account
  // ...

  // if (to.name === "loading") {
  //   store.dispatch("auth/logoutUser", {}, { root: true });
  //   next();
  //   return;
  // }

  try {
    const { user } = await store.dispatch(
      "auth/authenticate",
      {},
      { root: true }
    );

    isUserAuthenticated = user ? true : false;

    if (user) {
      email = user.email;
      isVerified = user.isVerified;
      isBeezIn = user.rolesIds.length < 1 ? false : true;
      if (isBeezIn) {
        for (var i in user.roles) {
          accountRoles.push(user.roles[i].resourceGroups[0].name);
        }
        // role = user.roles[0].resourceGroups[0].name;
      }
    }
    // console.log(user);
  } catch (error) {
    // ignore the error
    console.log(error);
    if (error.code === 404) {
      store.dispatch("auth/logoutUser", {}, { root: true });
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // code to handle routes which requires user authentication
    // console.log(to);
    if (!isUserAuthenticated) {
      next({
        name: "log-in",
      });
    } else {
      // If the user is not verified redirect to verify screen
      // If not beez-in then redirect to beez-in
      // If already verified and beez-in and tries to access
      // those screens redirect to dashboard
      // Else allow next navigation
      if (!isVerified) {
        if (to.name !== "verify") {
          next({
            name: "verify",
            query: { email: email },
          });
        } else {
          next();
        }
      } else if (!isBeezIn) {
        if (to.name !== "beez-in") {
          next({ name: "beez-in" });
        } else {
          next();
        }
      }
      // else if (to.name === "verify" || to.name === "beez-in") {
      //   next({ name: "account-dasboard" });
      // }
      else if (to.name === "verify") {
        next({ name: "admin" });
      } else if (to.name === "landing") {
        if (
          accountRoles.includes(roles.ACCOUNT_OWNER) &&
          (from.name === "verify" || from.name === "beez-in")
        ) {
          next();
        }
      } else {
        if (to.path.includes("/admin")) {
          if (accountRoles.includes(roles.ACCOUNT_OWNER)) {
            next();
          } else if (accountRoles.includes(roles.ONBOARDING_MODULE_MEMBER)) {
            next({ name: "user-home" });
          } else if (accountRoles.includes(roles.COURSE_EVALUATOR)) {
            next({ name: "evaluator-home" });
          }
        } else if (to.path.includes("/home")) {
          if (accountRoles.includes(roles.ONBOARDING_MODULE_MEMBER)) {
            next();
          } else if (accountRoles.includes(roles.ACCOUNT_OWNER)) {
            next({ name: "admin" });
          } else if (accountRoles.includes(roles.COURSE_EVALUATOR)) {
            next({ name: "evaluator-home" });
          }
        } else if (to.path.includes("/evaluator")) {
          if (accountRoles.includes(roles.COURSE_EVALUATOR)) {
            next();
          } else if (accountRoles.includes(roles.ONBOARDING_MODULE_MEMBER)) {
            next({ name: "user-home" });
          } else if (accountRoles.includes(roles.ACCOUNT_OWNER)) {
            next({ name: "admin" });
          }
        } else {
          next();
        }
      }
    }
  } else {
    // handle routes which does not requires auth
    // TODO: check here if the user is also verified

    if (!isUserAuthenticated) {
      next();
    } else {
      next({ name: "admin" });
    }
  }
});

export default router;
