<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="auto">
        <div class="title font-weight-regular textOrange--text">Company Logo</div>
        <v-card class="mt-4 primary" width="386" max-width="386" height="443" max-height="443">
          <div class="full-height d-flex justify-end align-center ma-0 pa-0">
            <v-img
              v-if="!account.logo"
              contain
              :aspect-ratio="1"
              src="../../assets/beez_social_erp.svg"
            ></v-img>
            <v-img v-else-if="photoToUpload" class="ma-12" :aspect-ratio="1" :src="photoToUpload"></v-img>
            <v-img v-else contain class="ma-12" :aspect-ratio="1" :src="account.logo.url"></v-img>
          </div>

          <v-footer absolute class="pa-0 ma-0">
            <v-btn
              tile
              color="primary darken-1"
              class="text-capitalize full-width full-height textLight--text"
              height="52"
              @click="$refs.accountLogoFile.click()"
            >
              <v-icon class="mr-2">mdi-upload</v-icon>
              <span class="title font-weight-regular">Upload Your Company Logo</span>
            </v-btn>
            <input
              id="account-logo"
              type="file"
              accept="image/*"
              ref="accountLogoFile"
              style="display: none;"
            />
          </v-footer>
        </v-card>
      </v-col>
      
      <v-col cols="auto">
        <div class="title font-weight-regular textOrange--text">General Settings</div>
        <v-card :disabled="loading" :loading="loading" class="mt-4" min-width="386" max-width="386" min-height="443" max-height="443">
          <div class="d-flex flex-column pa-8">
            <div class="d-flex justify-end">
              <span class="self-justify-end">######</span>
            </div>
            <div class="mt-4">
              <v-form v-model="formValid" ref="form">
                <v-text-field
                  v-model="account.companyName"
                  label="Company Name"
                  @input="$v.account.companyName.$touch"
                  :error-messages="companyNameErrorMessages"
                  class="mt-4"
                ></v-text-field>
                <v-text-field
                  @input="$v.account.name.$touch"
                  v-model="account.name"
                  label="Account Name"
                  :error-messages="accountNameErrorMessages"
                  class="mt-4"
                ></v-text-field>
                <v-tooltip v-model="showAllowedDomainsTip" bottom>
                  <template v-slot:activator="{ on }" bottom>
                    <v-combobox
                      v-model="account.domain"
                      v-on="on"
                      dense
                      hide-no-data
                      chips
                      deletable-chips
                      multiple
                      label="Allowed Domains"
                      append-icon
                      class="mt-4"
                      :rules="domainRules"
                      placeholder="example.com"
                    ></v-combobox>
                  </template>
                  <span>
                    Please enter all acceptable email
                    <br />domain addresses for your users
                    <br />(i.e. gmail.com,
                    yourcompany.com, etc)
                  </span>
                </v-tooltip>
              </v-form>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <bz-notification v-model="notification"></bz-notification>

    <v-footer height="65" absolute class="d-flex justify-center">
      <div class="d-flex flex-row">
        <p class="body-1 mr-12 my-2">Click on Save Button to Save changes</p>
        <bz-submit-button
          :loading="loading"
          :disabled="!accountChanged"
          title="Save Changes"
          depressed
          icon="mdi-content-save"
          type="submit"
          @click="save"
          width="240"
        >
          <v-divider vertical color="white" class="ml-4 mr-6" />
        </bz-submit-button>
      </div>
    </v-footer>

    <avatar-cropper
      v-show="photoCropper.show"
      @changed="showCropper(true)"
      trigger="#account-logo"
      :labels="photoCropper.labels"
      :cropper-options="{
        aspectRatio: 1
      }"
      :output-mime="photoCropper.outputMime"
      :upload-handler="uploadAvatar"
    />
  </v-container>
</template>

<script>
import BzSubmitButton from "@shared/components/BzSubmitButton";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { mapFields } from "vuex-map-fields";
import AvatarCropper from "vue-avatar-cropper";
import BzNotification from "@/shared/components/BzNotification";
import PhotoCorpperMixin from "@/shared/mixins/photo-cropper.mixin";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

export default {
  name: "AccountSettings",
  mixins: [PhotoCorpperMixin],
  components: {
    BzSubmitButton,
    BzNotification,
    AvatarCropper
  },

  async created() {
    this.account = cloneDeep(this.currentAccount);
  },

  data() {
    return {
      showAllowedDomainsTip: false,
      photoToUpload: null,
      photoBlobToUpload: null,
      formValid: false,
      account: null,
      accountChanged: false,
      loading: false,
      domainRules: [
        v => {
          if (!v || !v.length) {
            return true;
          }

          for (let i = 0; i < v.length; i++) {
            if (v.length > 0) {
              if (
                !/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
                  v[i]
                )
              ) {
                v.splice(i, 1);
              }
            } else return true;
          }
        }
      ]
    };
  },

  computed: {
    ...mapFields("auth", {
      currentUser: "currentUser",
      currentAccount: "currentAccount"
    }),
    ...mapFields("admin", ["allAccounts", "organizationLogo"]),

    accountNameErrorMessages() {
      const errors = [];
      if (!this.$v.account.name.$dirty) return [];
      !this.$v.account.name.required &&
        errors.push("Account Name is required.");
      return errors;
    },
    companyNameErrorMessages() {
      const errors = [];
      if (!this.$v.account.companyName.$dirty) return [];
      !this.$v.account.companyName.required &&
        errors.push("Company Name is required.");
      return errors;
    }
  },

  methods: {
    ...mapActions("auth", {
      updateAccount: "updateAccount",
      uploadAccountLogo: "uploadAccountLogo",
    }),

    async handleDialog() {
      let res = await this.$dialog.confirm({
        title: "Confirmation",
        text: "Are you sure you'd like to leave without saving changes?",
        persistent: true
      });
      return res;
    },

    async uploadAvatar(file) {
      this.photoToUpload = this.photoCropperToDataURL(file);
      this.photoBlobToUpload = await this.photoCropperGetBlob(file);
    },

    async save() {

      this.$v.$touch();
      
      if (this.$v.$invalid) {
        return;
      }
      
      try {
        this.loading = true;

        if (this.photoBlobToUpload) {
          const logo = await this.uploadAccountLogo(this.photoBlobToUpload);
          // update the new logo in the account
          this.account.logoFileId = logo._id;
          this.account.logo = logo;
        }

        // save the account
        await this.updateAccount(this.account);
        this.notify("success", "Account has been updated");

        // clone the updated account again in order to 
        // indicate that the account has not been changed after save
        this.account = cloneDeep(this.currentAccount);

      } finally {
        this.loading = false;
      }
    }
  },

  validations: {
    account: {
      companyName: {
        required
      },
      name: {
        required
      }
    }
  },

  watch: {
    account: {
      handler(newValue, oldValue) {
        // compare the updated value with the
        // original one using lodash isEqual (deep compare)
        this.accountChanged = !isEqual(newValue, this.currentAccount);
      },
      deep: true
    },
    photoToUpload: {
      handler(value) {
        if (!this.accountChanged && value) {
          this.accountChanged = true;
        }
      }
    }
  },
  // Navigation Guard
  async beforeRouteLeave(to, from, next) {
    if (this.accountChanged) {
      let answer = await this.handleDialog();
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>