<template>
  <v-container class="ma-0 pa-0">
    <v-card
      class="ma-0 py-1 px-0 overflowing"
      v-for="(tag, index) in listOfTags"
      :key="index"
      flat
      color="lightColor"
      :max-width="small ? 250 : null"
    >
      <v-row class="ma-0 pa-0">
        <v-col
          :class="darkColor"
          class="hidden-md-and-down d-flex justify-center align-center pa-0"
          sm="0"
          cols="0"
          md="1"
          lg="1"
          @click="onItemClicked"
        >
          <v-checkbox
            class="pa-0 mb-n3 mt-2 hidden-sm-and-down"
            v-if="hasCheckbox"
            v-model="selected"
            :value="tag"
            color="textLight"
            dark
          ></v-checkbox>
        </v-col>
        <v-col :class="lightColor" class="ma-0 pa-2" cols="12" sm="12" md="11" lg="11">
          <div :class="setText()" class="pa-0 ma-0 d-flex flex-row align-center">
            <v-checkbox
              class="ma-0 pa-0 mb-n3 mt-2 hidden-md-and-up"
              v-if="hasCheckbox"
              v-model="selected"
              :value="tag"
              color="textLight"
              dark
            ></v-checkbox>
            <a v-if="isLink" @click="openLink(tag)" target="_blank">
              <v-clamp ellipsis="..." :expanded="false" :max-lines="1">https://{{ tag }}</v-clamp>
            </a>
            <div v-else class="pa-0 ma-0" style="min-height: 20px;">
              <marquee-text
                v-if="small && tag.length > 12"
                :repeat="1"
                :paused="paused"
                class="my-0 pa-0 mr-2 ml-0 text-capitalize"
              >{{tag}}</marquee-text>
              <p v-else class="my-0 pa-0 mr-2 ml-0 tag-text-container text-capitalize">{{tag}}</p>
            </div>
            <v-spacer />
            <div v-if="editable" @click="onRemoveItem(index)" class="ma-0 pa-0">
              <v-btn small icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<style scoped>
.tag-text-container {
  word-break: break-all;
}
</style>
<script>
import MarqueeText from "vue-marquee-text-component";
import VClamp from "vue-clamp";

export default {
  name: "BzTags",
  data() {
    return {
      selected: [],
      paused: true
    };
  },
  components: {
    MarqueeText,
    VClamp
  },
  props: {
    darkColor: {
      type: String,
      required: true
    },
    lightColor: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      required: true
    },
    listOfTags: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    setTimeout(() => {
      this.paused = false;
    }, 3000);
  },
  methods: {
    setText() {
      if (this.small) {
        return "body-2 " + this.textColor;
      } else {
        return "subtitle-2 " + this.textColor;
      }
    },
    onRemoveItem(index) {
      // let fieldObject;
      this.$emit("removeItem", index);
    },
    onItemClicked() {
      this.$emit("onItemClicked", this.selected);
    },
    openLink(tag) {
      window.open(`http://${tag}`, "_blank");
    }
  }
};
</script>

<style scoped>
.overflowing {
  overflow: hidden;
}
</style>