import { getField, updateField } from "vuex-map-fields";
import {
  getOrientationTasks,
  createOrientationCard,
  patchOrientationCard,
} from "../../../api";

const state = {
  orientationCardData: [],
};

const actions = {
  async getOrientationTasks({ commit }, { accountId }) {
    const { data } = await getOrientationTasks(accountId);
    commit("updateField", {
      path: "orientationCardData",
      value: data,
    });

    return data;
  },
  async createOrientationCard({ _ }, { accountId, tasks }) {
    let configurationId = this.state.admin.configurationsIds[2];
    let accountModuleId = this.state.admin.onBoarding_accountModulesId;
    return await createOrientationCard(accountId, tasks, configurationId, accountModuleId);
  },
  async patchOrientationCard({ _ }, { cardId, accountId, tasks }) {
    return await patchOrientationCard(cardId, accountId, tasks);
  },
};

const mutations = {
  updateField,
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
