import { getField, updateField } from "vuex-map-fields";
import {
  getAccountModules,
  getAccountModuleMembers,
  setCommunicationPermission,
  createCommunicationModule,
  fetchCommunicationModule
} from "../../api";

const state = {
  currentModule: null,
  currentLink: null,
  currentModuleMembers: null,
  currentModulePermissionStatus: null,
  currentAccountModuleId: null,
  currentModuleFeaturePermissions: null
};

const actions = {
  // Fetch All Account Modules
  async fetchAccountModule({ commit }, { accountid }) {
    const data = await getAccountModules(accountid);
    console.log("Account Modules", data);
    commit("updateField", {
      path: "currentModule",
      value: data
    });
    return data;
  },

  // Fetch All  Module Members
  async fetchAccountModuleMembers(
    { commit },
    { level, accountid, accountmoduleid }
  ) {
    const members = await getAccountModuleMembers(
      level,
      accountid,
      accountmoduleid
    );

    commit("updateField", {
      path: "currentModuleMembers",
      value: members
    });
    return members;
  },

  //create Communication Modules

  async createCommunicationModule({ commit }, accountId) {
    const data = await createCommunicationModule(accountId);
    return data;
  },
  async assignCommunicationPermission(
    { commit },
    { adminIds, accountId, permission, accountModuleId }
  ) {
    const data = await setCommunicationPermission(
      adminIds,
      accountId,
      permission,
      accountModuleId
    );
    commit("updateField", {
      path: "currentModulePermission",
      value: data
    });
    return data;
  },

  async getCommunicationModule({ commit }, { accountId }) {
    const data = await fetchCommunicationModule(accountId);

    commit("updateField", {
      path: "currentModulePermissionStatus",
      value: data
    });
    return data;
  },

  // Handle the Current Communication Modules
  storeCurrentModuleId({ commit }, { id }) {
    commit("updateField", {
      path: "currentAccountModuleId",
      value: id
    });
  },

  // current Link to Route to
  storeRefreshFromLink({commit}, {Link}){ 
    commit("updateField", {
      path: "currentLink",
      value: Link
    });
  }
};

const mutations = {
  updateField
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
