import { getField, updateField } from "vuex-map-fields";
import {
  createIntroductionCard,
  getIntroductionCard,
  patchIntroductionCard,
  patchIntroductionCardTitles
} from "../../../api";

const state = {
  introductionCard: [],
  currentStepper: 0,
  completedSteps:[]
};

const actions = {
  async createIntroductionCard(
    { _ },
    { jobTitle, aboutTitle, sections, accountId }
  ) {
    // console.log(this.state);
    let configurationId = this.state.admin.configurationsIds[1];
    let accountModuleId = this.state.admin.onBoarding_accountModulesId;
    // console.log(jobTitle, aboutTitle, sections, accountId, configurationId)
    return await createIntroductionCard(
      jobTitle,
      aboutTitle,
      sections,
      accountId, 
      configurationId,
      accountModuleId
    );
  },
  async getIntroductionCard({ commit }, { accountId }) {
    const { data } = await getIntroductionCard(accountId);
    commit("updateField", {
      path: "introductionCard",
      value: data
    });

    return data;
  },
  async patchIntroductionCardTitles(
    { _ },
    { jobTitle, aboutTitle, accountId, cardId }
  ) {
    return await patchIntroductionCardTitles(jobTitle, aboutTitle, accountId,
      cardId);
  },
  async patchIntroductionCard({ _ }, { accountId, sectionId, title }) {
    return await patchIntroductionCard(accountId, sectionId, title);
  },

  // current Number of Stepper
  syncStep({ commit }, { Stepper }) {
    commit("updateField", {
      path: "currentStepper",
      value: Stepper
    });
  },

  // current Array of Completed Steps
  syncCompleted({ commit }, { completed }) { 
    commit("updateField", {
      path: "completedSteps",
      value: completed
    });
  }
};

const mutations = {
  updateField 
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
