import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "./styles/main.scss";

import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueSVGIcon from "vue-svgicon";
import "./compiled-icons";
import NotificationMixin from "./shared/mixins/notification.mixin";
import VuetifyDialog from "vuetify-dialog";
import BzFooter from "./shared/components/BzFooter.vue";

//Event bus used for communication between
//two child components
export const eventBus = new Vue();

Vue.mixin(NotificationMixin);
Vue.use(Vuelidate);
Vue.use(VueSVGIcon);
Vue.use(VuetifyDialog);
Vue.config.productionTip = false;
Vue.component("bzFooter", BzFooter);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
