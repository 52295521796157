import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        primary: "#119fc1",
        primaryLight: "#119fc1",
        primaryDark: "#0f8dab",
        secondary: "#fefefe",
        secondaryDark: "#CFCFCF",
        accent: "#fe7761",
        textOrange: "#FE664E",
        textDark: "#444444",
        textLight: "#FFFFFF",
        lightBlue: "#A7DCE8",
        lightOrange: "#FFC7BE",
        secondaryText: '#A1A1A1'
      }
    }
  }
});
 